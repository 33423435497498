/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./EditConcurso.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setNuevoConcurso,
  setRefreshNuevoConcurso,
  setStatusMessage,
} from "../../app/silices/concurso/concursoSlice";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import Input from "../Input/Input";
import { patchConcursoAsync } from "../../app/silices/concurso/concursoThunk";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTextEditor from "../../utils/customTextEditor";

const EditConcurso = (props) => {
  const { setEdit, login, concurso, idConcurso } = props;
  const dispatch = useDispatch();
  const { nuevoConcurso, statusMessage } = useSelector(
    (state) => state.concursoSlice
  );

  const [agregadoOk, setAgregadoOk] = useState();
  const [complete, setComplete] = useState(true);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const newConcurso = Object.assign({ selected: false }, concurso);
    newConcurso["banner_image"] = newConcurso["bannerImage"];
    newConcurso["end_date"] = formatDate(newConcurso["endDate"]);
    newConcurso["begin_date"] = formatDate(newConcurso["beginDate"]);
    newConcurso["additional_information"] = newConcurso["aditionalInformation"];
    newConcurso["winner_mail"] = newConcurso["winnerMail"];
    newConcurso["number_of_winners"] = newConcurso["numberOfWinners"];
    delete newConcurso["endDate"];
    delete newConcurso["beginDate"];
    delete newConcurso["numberOfWinners"];
    delete newConcurso["aditionalInformation"];
    delete newConcurso["winnerMail"];
    delete newConcurso["bannerImage"];
    dispatch(setNuevoConcurso(newConcurso));
  }, [concurso, dispatch]);

  useEffect(() => {
    if (statusMessage === "fulfilledPatchConcurso") {
      setAgregadoOk(true);
      setLoad(false);
      setTimeout(() => {
        // setAgregar(false)
        setAgregadoOk();
        dispatch(setStatusMessage(""));
        dispatch(setRefreshNuevoConcurso());
        window.location.reload();
      }, 2000);
    }

    if (statusMessage === "rejectedPatchConcurso") {
      setAgregadoOk(false);
      setLoad(false);
      setTimeout(() => {
        setAgregadoOk();
        dispatch(setStatusMessage(""));
      }, 7000);
    }
  }, [statusMessage]);

  useEffect(() => {
    if (complete === false) {
      setTimeout(() => {
        setComplete(true);
      }, 7000);
    }
  }, [complete]);

  const handlerChangeImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      const data64Imagen = base64String.split(",");
      dispatch(setNuevoConcurso({ image: data64Imagen[1] }));
    };

    reader.readAsDataURL(file);
  };

  const handlerChangeImageBanner = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      const data64Imagen = base64String.split(",");
      dispatch(setNuevoConcurso({ banner_image: data64Imagen[1] }));
    };

    reader.readAsDataURL(file);
  };

  const handlerChangeTitulo = (event) => {
    dispatch(setNuevoConcurso({ title: event.target.value }));
  };

  const handlerChangeAnunciante = (event) => {
    dispatch(setNuevoConcurso({ advertiser: event.target.value }));
  };

  const handlerChangePrograma = (event) => {
    dispatch(setNuevoConcurso({ program: event.target.value }));
  };

  const handlerChangeFinalizacion = (newValue) => {
    if (newValue != null) {
      let formattedDate = `${newValue.getFullYear()}-${(
        "0" +
        (newValue.getMonth() + 1)
      ).slice(-2)}-${("0" + newValue.getDate()).slice(-2)}T12:00:00`;
      dispatch(setNuevoConcurso({ end_date: formattedDate }));
    }
  };

  const handlerChangeInicio = (newValue) => {
    if (newValue != null) {
      let formattedDate = `${newValue.getFullYear()}-${(
        "0" +
        (newValue.getMonth() + 1)
      ).slice(-2)}-${("0" + newValue.getDate()).slice(-2)}T12:00:00`;
      dispatch(setNuevoConcurso({ begin_date: formattedDate }));
    }
  };

  const handlerChangeInfo = (content) => {
    dispatch(
      setNuevoConcurso({
        additional_information: content,
        description: " ",
      })
    );
  };

  const handlerChangeMail = (content) => {
    dispatch(setNuevoConcurso({ winner_mail: content, description: " " }));
  };

  const handleNumberOfWinner = (event) => {
    if (event.target.value > 0 && event.target.value <= 5) {
      dispatch(setNuevoConcurso({ number_of_winners: event.target.value }));
    }
  };

  const discardImage = () => {
    dispatch(setNuevoConcurso({ image: "" }));
  };

  const discardImageBanner = () => {
    dispatch(setNuevoConcurso({ banner_image: "" }));
  };

  const discardConcurso = () => {
    dispatch(setRefreshNuevoConcurso());
    setEdit(false);
  };

  const formatDate = (dateStr) => {
    let date = new Date(dateStr);

    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let day = String(date.getDate()).padStart(2, "0");

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };
  const addConcurso = () => {
    if (
      nuevoConcurso.title.length === 0 ||
      nuevoConcurso.image.length === 0 ||
      nuevoConcurso.end_date.length === 0 ||
      nuevoConcurso.begin_date.length === 0 ||
      nuevoConcurso.advertiser.length === 0 ||
      nuevoConcurso.program.length === 0 ||
      nuevoConcurso.banner_image.length === 0 ||
      nuevoConcurso.additional_information === 0 ||
      nuevoConcurso.winner_mail === 0 ||
      nuevoConcurso.number_of_winners < 1 ||
      nuevoConcurso.number_of_winners > 5
    ) {
      setComplete(false);
    } else {
      setComplete(true);
      setLoad(true);
      const body = {
        title: nuevoConcurso.title,
        description: nuevoConcurso.description,
        image: nuevoConcurso.image,
        end_date: new Date(nuevoConcurso.end_date).toISOString(),
        begin_date: new Date(nuevoConcurso.begin_date).toISOString(),
        advertiser: nuevoConcurso.advertiser,
        program: nuevoConcurso.program,
        banner_image: nuevoConcurso.banner_image,
        aditional_information: nuevoConcurso.additional_information,
        winner_mail: nuevoConcurso.winner_mail,
        number_of_winners: nuevoConcurso.number_of_winners,
      };
      dispatch(patchConcursoAsync({ body, token: login.token, idConcurso }));
    }
  };

  return (
    <section className="container-editar-concurso">
      <div className="box-informacion-agregar-concurso">
        <div className="boxsuperior-agregar-concurso">
          <div className="box-agregar-concurso">
            <div className="imgs-input-concurso-editar">
              <div className="img-input-concurso-agregar">
                {nuevoConcurso.image === "" ? (
                  <>
                    <span>Agregar imagen</span>
                    <div>
                      <EditIcon sx={{ fontSize: "15px" }} />
                    </div>
                    <input type="file" onChange={handlerChangeImage} />
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/jpg;base64,${nuevoConcurso.image}`}
                      alt="Nuevo concurso"
                      className="img-mini-agregar-concurso"
                    />
                    <div className="box-icon-imagen-agregar-concurso">
                      <DeleteForeverIcon onClick={discardImage} />
                    </div>
                  </>
                )}
              </div>

              <div className="img-input-banner-concurso-agregar">
                {nuevoConcurso.banner_image === "" ? (
                  <>
                    <span>Banner anunciante</span>
                    <div>
                      <EditIcon sx={{ fontSize: "15px" }} />
                    </div>
                    <input type="file" onChange={handlerChangeImageBanner} />
                  </>
                ) : (
                  <>
                    <img
                      src={`data:image/jpg;base64,${nuevoConcurso.banner_image}`}
                      alt="Nuevo concurso"
                      className="img-banner-agregar-concurso"
                    />
                    <div className="box-icon-imagen-agregar-concurso">
                      <DeleteForeverIcon onClick={discardImageBanner} />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="inbox-agregar-concurso-titulo">
              <div>
                <span className="span-agregar-concurso">Título:</span>
                <Input
                  type="text"
                  placeholder="Título"
                  width={4}
                  value={nuevoConcurso.title}
                  onChange={handlerChangeTitulo}
                  color
                />
              </div>

              <div>
                <span className="span-agregar-concurso">Anunciante:</span>
                <Input
                  type="text"
                  placeholder="Anunciante"
                  width={4}
                  value={nuevoConcurso.advertiser}
                  onChange={handlerChangeAnunciante}
                  color
                />
              </div>
            </div>
          </div>
        </div>

        <div className="boxsuperior2-agregar-concurso">
          <div>
            <div className="inbox-agregar-concurso">
              <span className="span-agregar-concurso">
                Programa que sortea:
              </span>
              <Input
                type="text"
                placeholder="Programa"
                width={4}
                value={nuevoConcurso.program}
                onChange={handlerChangePrograma}
                color
              />
            </div>

            <div>
              <span className="span-agregar-concurso">Fecha de inicio:</span>
              <DatePicker
                format="dd/MM/yyyy"
                className="date-picker"
                value={
                  nuevoConcurso.begin_date.length > 10
                    ? new Date(nuevoConcurso.begin_date)
                    : new Date(nuevoConcurso.begin_date + "T12:00:00")
                }
                onChange={handlerChangeInicio}
              />
            </div>

            <div>
              <span className="span-agregar-concurso">
                Fecha de finalización:
              </span>
              <DatePicker
                format="dd/MM/yyyy"
                className="date-picker"
                value={
                  nuevoConcurso.end_date.length > 10
                    ? new Date(nuevoConcurso.end_date)
                    : new Date(nuevoConcurso.end_date + "T12:00:00")
                }
                onChange={handlerChangeFinalizacion}
              />
            </div>
          </div>

          <div className="inbox2-agregar-concurso">
            <span className="span-agregar-concurso">Info Adicional:</span>
            <CustomTextEditor
              value={nuevoConcurso.additional_information}
              onChange={handlerChangeInfo}
            />
          </div>
          <div className="inbox2-agregar-concurso">
            <span className="span-agregar-concurso">Mail al ganador:</span>
            <CustomTextEditor
              value={nuevoConcurso.winner_mail}
              onChange={handlerChangeMail}
            />
          </div>

          <div className="number-of-winners">
            <span>Numero de ganadores</span>
            <input
              type={"number"}
              placeholder={"N de Ganadores"}
              required={true}
              onChange={handleNumberOfWinner}
              value={nuevoConcurso.number_of_winners}
              color={true}
            />
          </div>
        </div>
        <div className="box-botones-agregar-concurso">
          {load && (
            <span className="span-ok-registro span-cargando ">
              ... Cargando
            </span>
          )}
          {agregadoOk === true && (
            <span className="span-ok-registro">
              ¡Se edito el concurso correctamente!
            </span>
          )}
          {agregadoOk === false && (
            <span className="span-error-registro">
              ¡No se pudo editar el concurso!
            </span>
          )}
          {complete === false && (
            <span className="span-error-registro">
              ¡Datos incompletos complete todos los campos!
            </span>
          )}
          <button
            className="boton-guardar-agregar-concurso"
            onClick={addConcurso}
          >
            <SaveIcon />
            Guardar
          </button>

          <button
            className="boton-eliminar-agregar-concurso"
            onClick={discardConcurso}
          >
            <DeleteForeverIcon />
            Descartar
          </button>
        </div>
      </div>
    </section>
  );
};

export default EditConcurso;
