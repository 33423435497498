/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./oyenteMobile.css";
import TarjetaOyente from "../../components/TarjetasOyente/TarjetaOyente";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import logo from "../../assets/logo-lu5.svg";
import EditPerfil from "../../components/EditPerfil/EditPerfil";
import EditIcon from "@mui/icons-material/Edit";
import BannerPublicitario from "../../components/BannerPublicitario/BannerPublicitario";
import {
  setRefreshState,
  setStatusMessageLogin,
} from "../../app/silices/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { redirectToNewPage } from "../../utils/functions";
import { setRefreshStateGoogle } from "../../app/silices/usuarios/usuarioGoogleSlice";
import {
  setRefreshStateUser,
  setProfileuUsuario,
} from "../../app/silices/usuarios/usuarioSlice";
import { format } from "date-fns";
import { setRefreshStateBeneficio } from "../../app/silices/beneficio/beneficioSlice";
import { setRefreshStateConcursos } from "../../app/silices/concurso/concursoSlice";
import { Audio } from "react-loader-spinner";
import PlayCircleFilledSharpIcon from "@mui/icons-material/PlayCircleFilledSharp";
import ArticleIcon from "@mui/icons-material/Article";
import LogoutIcon from "@mui/icons-material/Logout";

import { Link } from "react-router-dom";
import ElementosOrdenados from "../../components/ListaElementosOrdenados/ElementosOrdenados";

const OyenteMobile = (props) => {
  const { setPerfil, profile } = props;
  const [opciones, setOpciones] = useState("concursos");
  const dispatch = useDispatch();
  const login = useSelector((state) => state.loginSlice);
  const user = useSelector((state) => state.usuarioSlice);
  const {
    concursos,
    concursosOyente,
    ganadores,
    loading,
  } = useSelector((state) => state.concursoSlice);
  const dataNotas = useSelector((state) => state.notaSlice);
  const dataBeneficios = useSelector((state) => state.beneficioSlice);
  const concursosSinOyente = concursos.filter((obj1) => {
    return !concursosOyente.some(
      (obj2) => obj2.id === obj1.id && obj2.name === obj1.name
    );
  });
  const concursosActivos = concursosSinOyente.filter(
    (concurso) => concurso.contestState !== "ENTREGADO"
  );
  const concursosVigentesActivos = concursosActivos.filter(
    (concurso) =>
      new Date().toISOString().split("T")[0] >= concurso.beginDate.split("T")[0]
  );
  const misConcursos = concursosOyente.map((contest) => {
    const matchingWinners = ganadores.filter(
      (winner) => winner.contestId === contest.id
    );
    const winnerNames = matchingWinners.map((winner) => winner.fullName);
    return {
      ...contest,
      winners: winnerNames,
    };
  });

  concursosVigentesActivos.sort(function (a, b) {
    if (a.stars > b.stars) {
      return -1;
    }
    if (a.stars < b.stars) {
      return 1;
    }
    var dateA = new Date(a.createdAt),
      dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  useEffect(() => {
    if (
      login.statusMessage === "rejectedToken" ||
      user.statusMessage === "rejectedLogin"
    ) {
      dispatch(setRefreshState());
      dispatch(setRefreshStateGoogle());
      dispatch(setRefreshStateUser());
      dispatch(setStatusMessageLogin(""));
      dispatch(setRefreshStateBeneficio());
      dispatch(setRefreshStateConcursos());
      redirectToNewPage("/");
    }
  }, [login.statusMessage, user.statusMessage]);

  const handleTituloNav = () => {
    if (opciones === "concursos") return "Concursos";
    if (opciones === "mis concursos") return "Mis Concursos";
    if (opciones === "beneficios") return "Beneficios";
    if (opciones === "perfil") return "Mi perfil";
  };

  const handleResultConcurso = (isWinner, contestState) => {
    if (isWinner === false && contestState === "ENTREGADO") {
      return "perdido";
    }

    if (isWinner === false && contestState === "PENDIENTE") {
      return "pendiente";
    }

    if (isWinner === true && contestState === "ENTREGADO") {
      return "ganado";
    }
  };



  const hanlderChangeImage = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      const data64Imagen = base64String.split(",");
      dispatch(setProfileuUsuario({ profileImage: data64Imagen[1] }));
    };

    reader.readAsDataURL(file);
  };

  const handleLogout = () => {
    setPerfil(false);
    dispatch(setRefreshState());
    dispatch(setRefreshStateGoogle());
    dispatch(setRefreshStateUser());
    dispatch(setRefreshStateBeneficio());
    dispatch(setRefreshStateConcursos());
    redirectToNewPage("/");
  };

  const contestsLength = concursosVigentesActivos.length;
  const adInterval = Math.min(Math.floor(contestsLength / 5), 3);
  const arrayBanners = concursosVigentesActivos.map((c, index) =>
    (index + 1) % adInterval === 0 && index < adInterval * 5 &&
    <BannerPublicitario
      adIndex={Math.floor((index + 1) / adInterval) - 1}
    />

  ).filter((c) => c)


  return (
    <section className="seccion-oyente-mobile">
      {opciones === "" && (
        <>
          <header className="header-oyente-mobile">
            <img src={logo} alt="Logo LU5" className="logo-mobile" />
            <img
              src={`data:image/jpg;base64,${profile.profileImage}`}
              alt="Oyente"
              className="img-nav-oyente img-nav-oyente-mobile"
            />
            <h2>{`Hola ${profile.fullName}!`}</h2>
          </header>

          <nav className="nav-mobile-oyente">
            <div className="box-botonera-mobile">
              <button
                className="button-nav-oyente-mobile"
                onClick={() => setOpciones("concursos")}
              >
                <p>Concursos</p>
              </button>
              <button
                className="button-nav-oyente-mobile"
                onClick={() => setOpciones("mis concursos")}
              >
                <p>Mis Concursos</p>
              </button>
              <button
                className="button-nav-oyente-mobile"
                onClick={() => setOpciones("beneficios")}
              >
                <p>Beneficios</p>
              </button>

              <div className="box-botonera-mobile box-botonera-mobile-perfil">
                <button
                  className="button-nav-oyente-mobile"
                  onClick={() => setOpciones("perfil")}
                >
                  <p>Mi perfil</p>
                </button>
              </div>
            </div>

            <div className="box-botonera-mobile box-botonera-mobile-perfil box-botonera-redirecciones">
              <div>
                <PlayCircleFilledSharpIcon
                  style={{ color: "red", fontSize: "3rem" }}
                  onClick={() =>
                    window.open("https://lu5am.com/en-vivo/", "_blank")
                  }
                />
                <p style={{ fontSize: "0.75rem" }}>Escuchá la radio en vivo</p>
              </div>
              <div>
                <ArticleIcon
                  style={{ color: "red", fontSize: "3rem" }}
                  onClick={() =>
                    window.open(
                      "https://lu5am.com/category/noticias/",
                      "_blank"
                    )
                  }
                />
                <p style={{ fontSize: "0.75rem" }}>Leer las últimas noticias</p>
              </div>
            </div>
            <Link
              to={"/"}
              className="opcion-nav-oyente opcion-nav-oyente-cerrasesion"
              onClick={handleLogout}
            >
              <LogoutIcon
                sx={{ fontSize: "18px", background: "transparent" }}
              />
              Cerrar sesión
            </Link>
          </nav>
        </>
      )}

      {(opciones === "concursos" ||
        opciones === "mis concursos" ||
        opciones === "beneficios" ||
        opciones === "perfil") && (
          <>
            <header className="header-oyente-mobile-2">
              <img src={logo} alt="Logo LU5" className="logo-mobile-2" />

              <div className="box-header-mobile">
                <div className="inbox-header-mobile">
                  <h2 className="titulo-header-mobile">{`Hola ${profile.fullName}!`}</h2>
                </div>

                <img
                  onClick={() => setOpciones("perfil")}
                  src={`data:image/jpg;base64,${profile.profileImage}`}
                  alt="Oyente"
                  className="img-nav-oyente img-nav-oyente-mobile-2"
                />
              </div>
            </header>
            <nav className="atras-nav-mobile" onClick={() => setOpciones("")}>
              <ArrowBackIosNewIcon /> <p>{handleTituloNav()}</p>
            </nav>
          </>
        )}

      {
        (opciones === "concursos")
        &&
        <>
          <ElementosOrdenados
            banners={arrayBanners}
            concursos={concursosVigentesActivos}
            notas={dataNotas.notas}
          />

        </>
      }


      {opciones === "mis concursos" && (
        <>
          {loading ? (
            <div className="loader-all loader-oyente">
              <Audio
                height="80"
                width="80"
                radius="9"
                color="red"
                ariaLabel="Cargando..."
              />
              <p>Cargando...</p>
            </div>
          ) : (
            misConcursos.map((concurso, index) => (
              <TarjetaOyente
                key={index}
                tipo={"mis concursos"}
                ganadores={concurso.winners}
                titulo={concurso.title}
                img={`data:image/jpg;base64,${concurso.image}`}
                estadoMiSorteo={concurso.contestState}
                fechaParticipacion={format(
                  new Date(concurso.participateDate),
                  "dd-MM-yyyy"
                )}
                resultadoMiSorteo={handleResultConcurso(
                  concurso.isWinner,
                  concurso.contestState
                )}
                infoModal={concurso.description}
              />
            ))
          )}
        </>
      )}

      {opciones === "beneficios" && (
        <>
          {dataBeneficios.loading ? (
            <div className="loader-all loader-oyente">
              <Audio
                height="80"
                width="80"
                radius="9"
                color="red"
                ariaLabel="Cargando..."
              />
              <p>Cargando...</p>
            </div>
          ) : (
            dataBeneficios.beneficios.map((beneficio, index) => (
              <TarjetaOyente
                key={index}
                tipo={"beneficios"}
                titulo={beneficio.title}
                img={`data:image/jpg;base64,${beneficio.image}`}
                fechaFinBeneficio={format(
                  new Date(beneficio.endDate),
                  "dd-MM-yyyy"
                )}
                usoBeneficio={beneficio.benefitUse}
                codigoDescuento={beneficio.discountCode}
              />
            ))
          )}
        </>
      )}

      {opciones === "perfil" && (
        <>
          <div className="editar-foto-perfil-mobile">
            <div className="inbox-editar-foto-perfil-mobile">
              <EditIcon sx={{ fontSize: "16px" }} />
              <p>Editar foto de perfil</p>

              <input type="file" onChange={hanlderChangeImage} />
            </div>
          </div>

          <EditPerfil
            setPerfil={setPerfil}
            profile={profile}
            login={login}
            statusMessage={user.statusMessage}
          />
        </>
      )}
    </section>
  );
};

export default OyenteMobile;
