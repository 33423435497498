/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./vistasAdmin.css";
import VistaConcursos from "./VistaConcursos/VistaConcursos";
import VistaUsuarios from "./VistaUsuarios/VistaUsuarios";
import VistaEstadisticas from "./VistaEstadisticas/VistaEstadisticas";
import VistaPerfil from "./VistaPerfil/VistaPerfil";
import VistaBeneficios from "./VistaBeneficios/VistaBeneficios";
import { useSelector } from "react-redux";
import VistaNotas from "./VistaNotas/VistaNotas";


const VistasAdmin = (props) => {
  const {
    opcion,
    dataConcursos,
    newConcurso,
    setNewConcurso,
    programasConcursos,
    dataBeneficios,
    dataNotas,
  } = props;
  const login = useSelector((state) => state.loginSlice);

  return (
    <section>
      {opcion === "concursos" && (
        <VistaConcursos
          login={login}
          dataConcursos={dataConcursos}
          newConcurso={newConcurso}
          setNewConcurso={setNewConcurso}
        />
      )}

      {opcion === "usuarios" && <VistaUsuarios />}

      {opcion === "estadisticas" && (
        <VistaEstadisticas
          programasConcursos={programasConcursos}
          dataConcursos={dataConcursos}
        />
      )}

      {opcion === "beneficios" && (
        <VistaBeneficios login={login} dataBeneficios={dataBeneficios} />
      )}
      {opcion === "notas" && (
        <VistaNotas login={login} dataNotas={dataNotas} />
      )}

      {opcion === "perfil" && <VistaPerfil />}
    </section>
  );
};

export default VistasAdmin;
