/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./homeOyente.css";
import TarjetaOyente from "../TarjetasOyente/TarjetaOyente";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import BannerPublicitario from "../BannerPublicitario/BannerPublicitario";
import { getAllNotasAsync } from "../../app/silices/notas/notaThunk";
import ElementosOrdenados from "../ListaElementosOrdenados/ElementosOrdenados";

const HomeOyente = (props) => {
  const { login } = props;
  const [opcion, setOpcion] = useState("concursos");
  const { concursos, concursosOyente, ganadores } = useSelector(
    (state) => state.concursoSlice
  );
  const { beneficios } = useSelector((state) => state.beneficioSlice);
  const dataNotas = useSelector((state) => state.notaSlice);
  const dispatch = useDispatch();

  const concursosSinOyente = concursos.filter((obj1) => {
    return !concursosOyente.some(
      (obj2) => obj2.id === obj1.id && obj2.name === obj1.name
    );
  });

  useEffect(() => {
    dispatch(getAllNotasAsync({ token: login.token }));
  }, []);

  const concursosActivos = concursosSinOyente.filter(
    (concurso) => concurso.contestState !== "ENTREGADO"
  );

  const concursosActivosOyente = concursosOyente.filter(
    (concurso) => concurso.contestState !== "ENTREGADO"
  );
  const concursosVigentesActivos = concursosActivos.filter(
    (concurso) =>
      new Date().toISOString().split("T")[0] >= concurso.beginDate.split("T")[0]
  );
  const misConcursos = concursosOyente.map((contest) => {
    const matchingWinners = ganadores.filter(
      (winner) => winner.contestId === contest.id
    );
    const winnerNames = matchingWinners.map((winner) => winner.fullName);
    return {
      ...contest,
      winners: winnerNames,
    };
  });

  concursosVigentesActivos.sort(function (a, b) {
    if (a.stars > b.stars) {
      return -1;
    }
    if (a.stars < b.stars) {
      return 1;
    }
    var dateA = new Date(a.createdAt),
      dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  const handleResultConcurso = (isWinner, contestState) => {
    if (isWinner === false && contestState === "ENTREGADO") {
      return "perdido";
    }

    if (isWinner === false && contestState === "PENDIENTE") {
      return "pendiente";
    }

    if (isWinner === true && contestState === "ENTREGADO") {
      return "ganado";
    }
  };

  const contestsLength = concursosVigentesActivos.length;
  const adInterval = Math.min(Math.floor(contestsLength / 5), 3);
  const arrayBanners = concursosVigentesActivos
    .map(
      (c, index) =>
        (index + 1) % adInterval === 0 &&
        index < adInterval * 5 && (
          <BannerPublicitario
            adIndex={Math.floor((index + 1) / adInterval) - 1}
          />
        )
    )
    .filter((c) => c);

  return (
    <section className="container-home-oyente">
      <nav className="nav-home-oyente">
        <p
          className={`opcion-nav-home-oyente ${
            opcion === "concursos" ? "opcion-nav-home-oyente-selected" : ""
          }`}
          onClick={() => setOpcion("concursos")}
        >
          Concursos
        </p>
        <p
          className={`opcion-nav-home-oyente ${
            opcion === "mis concursos" ? "opcion-nav-home-oyente-selected" : ""
          }`}
          onClick={() => setOpcion("mis concursos")}
        >
          Mis concursos
        </p>
        <p
          className={`opcion-nav-home-oyente ${
            opcion === "beneficios" ? "opcion-nav-home-oyente-selected" : ""
          }`}
          onClick={() => setOpcion("beneficios")}
        >
          Beneficios
        </p>
      </nav>
      {concursosActivosOyente.length > 0 && (
        <h2>
          Participando en {concursosActivosOyente.length}{" "}
          {concursosActivosOyente.length === 1 ? "Concurso" : "Concursos"}
        </h2>
      )}

      <div className="box-home-oyente">
        {opcion === "concursos" && (
          <ElementosOrdenados
            banners={arrayBanners}
            concursos={concursosVigentesActivos}
            notas={dataNotas.notas}
          />
        )}

        {/* {opcion === "concursos" &&
          concursosVigentesActivos.map((concurso, index) => (
            <>
              <TarjetaOyente
                key={index}
                tipo={"concursos"}
                titulo={concurso.title}
                img={`data:image/jpg;base64,${concurso.image}`}
                programaSorteo={concurso.program}
                finalizacionConcurso={format(
                  new Date(concurso.endDate),
                  "dd-MM-yyyy"
                )}
                infoModal={concurso.aditionalInformation}
                nombrePrograma={concurso.program}
                parrafo={concurso.aditionalInformation}
                imgModalConcurso={`data:image/jpg;base64,${concurso.bannerImage}`}
                estadoSorteo={concurso.contestState}
                login={login}
                idConcurso={concurso.id}
                concursosOyente={concursosOyente}
                ganadores={ganadores}
              />

              {(index + 1) % adInterval === 0 && index < adInterval * 5 ? (
                <BannerPublicitario
                  adIndex={Math.floor((index + 1) / adInterval) - 1}
                />
              ) :
                dataNotas.notas.map((nota) => (
                  <Publinota
                    {...nota}
                    key={nota.id}
                    imgSize={140}
                  />
                ))

              }
            </>
          ))} */}

        {opcion === "mis concursos" &&
          misConcursos.map((concurso, index) => (
            <>
              <TarjetaOyente
                key={index}
                tipo={"mis concursos"}
                titulo={concurso.title}
                ganadores={concurso.winners}
                img={`data:image/jpg;base64,${concurso.image}`}
                estadoMiSorteo={concurso.contestState}
                fechaParticipacion={format(
                  new Date(concurso.participateDate),
                  "dd-MM-yyyy"
                )}
                resultadoMiSorteo={handleResultConcurso(
                  concurso.isWinner,
                  concurso.contestState
                )}
                infoModal={concurso.description}
              />
            </>
          ))}

        {opcion === "beneficios" &&
          beneficios.map((beneficio, index) => (
            <TarjetaOyente
              key={index}
              tipo={"beneficios"}
              titulo={beneficio.title}
              img={`data:image/jpg;base64,${beneficio.image}`}
              fechaFinBeneficio={format(
                new Date(beneficio.endDate),
                "dd-MM-yyyy"
              )}
              usoBeneficio={beneficio.benefitUse}
              codigoDescuento={beneficio.discountCode}
            />
          ))}
      </div>
    </section>
  );
};

export default HomeOyente;
