import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { deleteNotaAsync } from "../../app/silices/notas/notaThunk";
import "../Beneficio/beneficio.css";

const NotaAdmin = (props) => {
    const {
        image,
        title,
        description,
        link,
        id,
    } = props;
    const [eliminar, setEliminar] = useState(false);
    const dispatch = useDispatch();
    const login = useSelector((state) => state.loginSlice);
    const { role, token } = login;


    const hanlderDelete = () => {
        dispatch(deleteNotaAsync({ token, id }));
        setEliminar(true);
    };

    return (
        <section>
            <section className={`container-beneficio-vista ${eliminar ? "container-beneficio-vista-hidden" : ""}`}            >
                <div className="boxsuperior-beneficio-vista">
                    <div className="box-beneficio-vista">
                        <img
                            src={`data:image/jpg;base64,${image}`}
                            alt="Nota LU5"
                            className="img-principal-beneficio-vista"
                        />
                        <div>
                            <h2 className="titulo-beneficio-vista">{title}</h2>

                            <div className="inbox-beneficio-vista">
                                <span className="span-beneficio-vista">
                                    Link de promoción:
                                </span>
                                <p className="parrafo-beneficio-vista">
                                    <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="boxsuperior2-beneficio-vista">
                    <div className="inbox2-beneficio-vista">
                        <h2 className="titulo-beneficio-vista">Descripción:</h2>
                        <p className="parrafo-beneficio-vista">{description}</p>
                    </div>
                </div>
                {role === "admin" || role === "comercial" ? (
                    <button
                        className="boton-eliminar-beneficio-vista"
                        onClick={hanlderDelete}
                    >
                        <DeleteForeverIcon />
                        Eliminar
                    </button>
                ) : (
                    <></>
                )}
            </section>
        </section>
    );
};

export default NotaAdmin;
