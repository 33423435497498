import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// Specify the Quill modules and formats you want to use
const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code-block",
  "formula",
  "script",
  "direction",
  "size",
  "font",
  "color",
  "background",
  "align",
];

// Create a new component that uses these settings
const CustomTextEditor = (props) => (
  <ReactQuill {...props} modules={modules} formats={formats} />
);

export default CustomTextEditor;
