import React from "react";
import { format } from "date-fns";
import TarjetaOyente from "../TarjetasOyente/TarjetaOyente";
import { Nota } from "../Nota/Nota";
import { useSelector } from "react-redux";

const ElementosOrdenados = ({ concursos, notas, banners }) => {
  const login = useSelector((state) => state.loginSlice);
  let concursosTipados = concursos.map((concurso, index) => ({
    ...concurso,
    tipado: "concurso",
    index,
  }));
  let notasTipadas = notas.map((nota, index) => ({
    ...nota,
    tipado: "nota",
    index,
  }));
  let bannersTipados = banners.map((banner, index) => ({
    ...banner,
    tipado: "banner",
    index,
  }));
  let arrayToRender = [];

  let concursosTipadosAux = [...concursosTipados];
  let notasTipadasAux = [...notasTipadas];
  let bannersTipadosAux = [...bannersTipados];

  concursosTipados.map((concurso) => {
    if (arrayToRender.length > 0) {
      const anteUltimoElemento = arrayToRender[arrayToRender.length - 2];

      if (anteUltimoElemento) {
        if (anteUltimoElemento.tipado === "nota") {
          if (bannersTipadosAux.length > 0) {
            const banner = bannersTipadosAux.shift();
            arrayToRender.push(banner);
          } else if (notasTipadasAux.length > 0) {
            const nota = notasTipadasAux.shift();
            arrayToRender.push(nota);
          }
        } else if (anteUltimoElemento.tipado === "banner") {
          if (notasTipadasAux.length > 0) {
            const nota = notasTipadasAux.shift();
            arrayToRender.push(nota);
          } else if (bannersTipadosAux.length > 0) {
            const banner = bannersTipadosAux.shift();
            arrayToRender.push(banner);
          }
        }

        arrayToRender.push(concurso);
        concursosTipadosAux = concursosTipadosAux.filter(
          (c) => c.id !== concurso.id
        );
      } else {
        if (notasTipadasAux.length > 0) {
          const nota = notasTipadasAux.shift();
          arrayToRender.push(nota);
        } else if (bannersTipadosAux.length > 0) {
          const banner = bannersTipadosAux.shift();
          arrayToRender.push(banner);
        }

        arrayToRender.push(concurso);
        concursosTipadosAux = concursosTipadosAux.filter(
          (c) => c.id !== concurso.id
        );
      }
    } else {
      arrayToRender.push(concurso);
      concursosTipadosAux = concursosTipadosAux.filter(
        (c) => c.id !== concurso.id
      );
    }
  });

  notasTipadas = [...notasTipadasAux];
  notasTipadasAux.map((nota) => {
    if (arrayToRender.length > 0) {
      const ultimoElemento = arrayToRender[arrayToRender.length - 1];

      if (ultimoElemento.tipado === "nota" && bannersTipadosAux.length > 0) {
        const banner = bannersTipadosAux.shift();
        arrayToRender.push(banner);
      }
      arrayToRender.push(nota);
      notasTipadasAux = notasTipadasAux.filter((n) => n.id !== nota.id);
    } else {
      arrayToRender.push(nota);
      notasTipadasAux = notasTipadasAux.filter((n) => n.id !== nota.id);
    }
  });

  bannersTipados = [...bannersTipadosAux];
  bannersTipadosAux.map((banner) => {
    arrayToRender.push(banner);
    bannersTipadosAux = bannersTipadosAux.filter((b) => b.id !== banner.id);
  });

  return (
    <div>
      {arrayToRender.map((elemento) => {
        switch (elemento.tipado) {
          case "nota":
            return <Nota {...elemento} imgSize={140} logoSize={140} key={elemento.id} />;
          case "concurso":
            return (
              <TarjetaOyente
                key={elemento.id}
                tipo={"concursos"}
                titulo={elemento.title}
                img={`data:image/jpg;base64,${elemento.image}`}
                programaSorteo={elemento.program}
                login={login}
                finalizacionConcurso={format(
                  new Date(elemento.endDate),
                  "dd-MM-yyyy"
                )}
                infoModal={elemento.aditionalInformation}
                nombrePrograma={elemento.program}
                parrafo={elemento.aditionalInformation}
                imgModalConcurso={`data:image/jpg;base64,${elemento.bannerImage}`}
                estadoSorteo={elemento.contestState}
                idConcurso={elemento.id}
              />
            );
          default:
            return elemento;
        }
      })}
    </div>
  );
};

export default ElementosOrdenados;
