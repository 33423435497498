/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./vistaEstadisticas.css";
import TableEstadisticas from "../../EstadisticaTable/TableContainer";

const VistaEstadisticas = (props) => {
  const { dataConcursos } = props;

  // const statsConcursos = dataConcursos.concursos.map((concurso) => {
  //   const filter = dataConcursos.concursos.filter(
  //     (data) => data.title === concurso.title
  //   );
  //   return { id: concurso.id, name: concurso.title, Cantidad: filter.length };
  // });

  // const statsParticipantes = dataConcursos.cantidadParticipantes.map(
  //   (participante) => {
  //     return {
  //       name: participante.contestName,
  //       Cantidad: participante.participants,
  //     };
  //   }
  // );

  // Function to format date string to DD/MM/YYYY
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const resultArray = [];

  dataConcursos.concursos.forEach((concurso) => {
    const cantidadConcursos = dataConcursos.concursos.filter(
      (data) => data.title === concurso.title
    ).length;
    const participante = dataConcursos.cantidadParticipantes.find(
      (part) => part.contestId === concurso.id
    );

    resultArray.push({
      id: concurso.id,
      title: concurso.title,
      cantidad_concursos: cantidadConcursos,
      cantidad_participantes: participante ? participante.participants : 0,
      beginDate: formatDate(concurso.beginDate),
      endDate: formatDate(concurso.endDate),
    });
  });

  // const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
  //   return (
  //     <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>
  //       {value}
  //     </text>
  //   );
  // };

  return (
    <TableEstadisticas data={resultArray}></TableEstadisticas>
    // <section>
    //   <h2 className="titulo-vista-estadisticas-admin">Estadísticas</h2>

    //   <section className="container-graficos-estadisticas">
    //     <div className="box-grafico-estadisticas">
    //       <h2 className="titulo-grafico-estadisticas">
    //         Cantidad de concursos realizados
    //       </h2>

    //       {statsConcursos.length === 0 ? (
    //         <h2 className="datos-insuficientes-estadisticas">
    //           Datos insuficientes
    //         </h2>
    //       ) : (
    //         <BarChart
    //           width={statsConcursos.length * 20}
    //           height={250}
    //           data={statsConcursos}
    //         >
    //           <XAxis dataKey="name" style={{ fontSize: "12px" }} />
    //           <Tooltip />
    //           <Bar
    //             dataKey="Cantidad"
    //             barSize={30}
    //             fill="#8884d8"
    //             label={renderCustomBarLabel}
    //           />
    //         </BarChart>
    //       )}
    //     </div>

    //     <div className="box-grafico-estadisticas">
    //       <h2 className="titulo-grafico-estadisticas">
    //         Programa con más participación
    //       </h2>

    //       {programasConcursos.length === 0 ? (
    //         <h2 className="datos-insuficientes-estadisticas">
    //           Datos insuficientes
    //         </h2>
    //       ) : (
    //         <BarChart
    //           width={programasConcursos.length * 20}
    //           height={250}
    //           data={programasConcursos}
    //         >
    //           <XAxis dataKey="name" style={{ fontSize: "12px" }} />
    //           <Tooltip />
    //           <Bar
    //             dataKey="Cantidad"
    //             barSize={30}
    //             fill="#8884d8"
    //             label={renderCustomBarLabel}
    //           />
    //         </BarChart>
    //       )}
    //     </div>

    //     <div className="box-grafico-estadisticas">
    //       <h2 className="titulo-grafico-estadisticas">
    //         Cantidad de participantes en concursos
    //       </h2>

    //       {statsParticipantes.length === 0 ? (
    //         <h2 className="datos-insuficientes-estadisticas">
    //           Datos insuficientes
    //         </h2>
    //       ) : (
    //         <BarChart
    //           width={statsParticipantes.length * 20}
    //           height={250}
    //           data={statsParticipantes}
    //         >
    //           <XAxis dataKey="name" style={{ fontSize: "12px" }} />
    //           <Tooltip />
    //           <Bar
    //             dataKey="Cantidad"
    //             barSize={30}
    //             fill="#8884d8"
    //             label={renderCustomBarLabel}
    //           />
    //         </BarChart>
    //       )}
    //     </div>
    //   </section>
    // </section>
  );
};

export default VistaEstadisticas;
