import { createContext } from "react";



const defaultValue = {
    setOpenModal: (open) => { },
    openModal: false,
};

export const ModalContext = createContext(defaultValue);

export const ModalProvider = ({ children }) => {



    return <ModalContext.Provider>
        {children}
    </ModalContext.Provider>
}