import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllConcursos,
  patchConcursoStars,
  getConcurso,
  postMailWinner,
  postParticipanteConcurso,
  postConcurso,
  patchConcurso,
  deleteConcurso,
  getConcursosWinners,
  patchWinnerConcurso,
  getConcursosOyente,
  getParticipantesEnConcursos,
} from "./concursoApi";

export const getAllConcursosAsync = createAsyncThunk(
  "concursos/getAllConcursos",
  async (args) => {
    const { token } = args;
    const response = await getAllConcursos(token);
    return response;
  }
);

export const getConcursoAsync = createAsyncThunk(
  "concursos/getAllConcursos",
  async (args) => {
    const { token, idUser } = args;
    const response = await getConcurso(token, idUser);
    return response;
  }
);

export const getConcursosWinnersAsync = createAsyncThunk(
  "concursos/getConcursosWinners",
  async (args) => {
    const { token } = args;
    const response = await getConcursosWinners(token);
    return response;
  }
);

export const getConcursosOyenteAsync = createAsyncThunk(
  "concursos/getConcursosOyente",
  async (args) => {
    const { token, idUsuario } = args;
    const response = await getConcursosOyente(token, idUsuario);
    return response;
  }
);

export const getParticipantesEnConcursosAsync = createAsyncThunk(
  "concursos/getParticipantesEnConcursos",
  async (args) => {
    const { token } = args;
    const response = await getParticipantesEnConcursos(token);
    return response;
  }
);

export const postParticipanteConcursoAsync = createAsyncThunk(
  "concursos/postParticipanteConcurso",
  async (args) => {
    const { body, token } = args;
    const response = await postParticipanteConcurso(body, token);
    return response;
  }
);

export const postConcursoAsync = createAsyncThunk(
  "concursos/postConcurso",
  async (args) => {
    const { body, token } = args;
    const response = await postConcurso(body, token);
    return response;
  }
);

export const postMailWinnerAsync = createAsyncThunk(
  "concursos/postMailWinner",
  async (args) => {
    const { body, token } = args;
    const response = await postMailWinner(body, token);
    return response;
  }
);

export const patchConcursoAsync = createAsyncThunk(
  "concursos/patchConcurso",
  async (args) => {
    const { body, token, idConcurso } = args;
    const response = await patchConcurso(body, token, idConcurso);
    return response;
  }
);

export const patchConcursoStarsAsync = createAsyncThunk(
  "concursos/patchConcursoStars",
  async (args) => {
    const { body, token } = args;
    const response = await patchConcursoStars(body, token);
    return response;
  }
);

export const patchWinnerConcursoAsync = createAsyncThunk(
  "concursos/patchWinnerConcurso",
  async (args) => {
    const { idConcurso, token } = args;
    const response = await patchWinnerConcurso(idConcurso, token);
    return response;
  }
);

export const deleteConcursoAsync = createAsyncThunk(
  "concursos/deleteConcurso",
  async (args) => {
    const { token, idConcurso } = args;
    const response = await deleteConcurso(token, idConcurso);
    return response;
  }
);
