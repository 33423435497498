import { createSlice } from "@reduxjs/toolkit";
import {
  getAllConcursosAsync,
  postMailWinnerAsync,
  postParticipanteConcursoAsync,
  patchConcursoAsync,
  postConcursoAsync,
  deleteConcursoAsync,
  getConcursosWinnersAsync,
  patchWinnerConcursoAsync,
  getConcursosOyenteAsync,
  getParticipantesEnConcursosAsync,
} from "./concursoThunk";

const initialState = {
  concursos: [],
  nuevoConcurso: {
    title: "",
    description: "",
    //placeholder image
    image: "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    end_date: "",
    begin_date: "",
    advertiser: "",
    program: "",
    //placeholder image
    banner_image: "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
    aditional_information: "",
    winner_mail: "",
    number_of_winners: 1,
  },
  cantidadParticipantes: [],
  ganadores: [],
  concursosOyente: [],
  loading: false,
  loadingConcursos: false,
  statusMessage: "",
  statusSubscriptionToContest: "",
};

export const concursoSlice = createSlice({
  name: "concurso",
  initialState,
  reducers: {
    setNuevoConcurso: (state, action) => {
      state.nuevoConcurso = { ...state.nuevoConcurso, ...action.payload };
    },
    setRefreshStateConcursos: () => initialState,
    setRefreshNuevoConcurso: (state, action) => {
      state.nuevoConcurso = {
        title: "",
        description: "",
        //placeholder image
        image: "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
        end_date: "",
        begin_date: "",
        advertiser: "",
        program: "",
        //placeholder image
        banner_image: "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
        aditional_information: "",
        winner_mail: "",
        number_of_winners: 1,
      };
    },
    setStatusMessage: (state, action) => {
      state.statusMessage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAllConcursosAsync.pending, (state, action) => {
        state.statusMessage = "pendingAllConcursos";
        state.loadingConcursos = true;
      })
      .addCase(getAllConcursosAsync.fulfilled, (state, action) => {
        state.concursos = action.payload.data.data;
        state.statusMessage = "fulfilledAllConcursos";
        state.loadingConcursos = false;
      })
      .addCase(getAllConcursosAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedAllConcursos";
        state.loadingConcursos = false;
      })
      .addCase(getConcursosWinnersAsync.pending, (state, action) => {
        state.statusMessage = "pendingConcursosWinners";
        state.loading = true;
      })
      .addCase(getConcursosWinnersAsync.fulfilled, (state, action) => {
        state.ganadores = action.payload.data.data;
        state.statusMessage = "fulfilledConcursosWinners";
        state.loading = false;
      })
      .addCase(getConcursosWinnersAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedConcursosWinners";
        state.loading = false;
      })

      .addCase(getConcursosOyenteAsync.pending, (state, action) => {
        state.statusMessage = "pendingConcursosOyente";
        state.loading = true;
      })
      .addCase(getConcursosOyenteAsync.fulfilled, (state, action) => {
        state.concursosOyente = action.payload.data.data;
        state.statusMessage = "fulfilledConcursosOyente";
        state.loading = false;
      })
      .addCase(getConcursosOyenteAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedConcursosOyente";
        state.loading = false;
      })

      .addCase(getParticipantesEnConcursosAsync.pending, (state, action) => {
        state.statusMessage = "pendingCantidadParticipantes";
        state.loading = true;
      })
      .addCase(getParticipantesEnConcursosAsync.fulfilled, (state, action) => {
        state.cantidadParticipantes = action.payload.data.data;
        state.statusMessage = "fulfilledCantidadParticipantes";
        state.loading = false;
      })
      .addCase(getParticipantesEnConcursosAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedCantidadParticipantes";
        state.loading = false;
      })

      .addCase(postParticipanteConcursoAsync.pending, (state, action) => {
        state.statusMessage = "pendingParticipanteConcurso";
        state.statusSubscriptionToContest = "pendingParticipanteConcurso";
        state.loading = true;
      })
      .addCase(postParticipanteConcursoAsync.fulfilled, (state, action) => {
        state.statusMessage = "fulfilledParticipanteConcurso";
        state.statusSubscriptionToContest = "fulfilledParticipanteConcurso";

        state.loading = false;
      })
      .addCase(postParticipanteConcursoAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedParticipanteConcurso";
        state.statusSubscriptionToContest = "rejectedParticipanteConcurso";
        state.loading = false;
      })

      .addCase(postConcursoAsync.pending, (state, action) => {
        state.statusMessage = "pendingPostConcurso";
        state.loading = true;
      })
      .addCase(postConcursoAsync.fulfilled, (state, action) => {
        state.statusMessage = "fulfilledPostConcurso";
        state.loading = false;
      })
      .addCase(postConcursoAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedPostConcurso";
        state.loading = false;
      })
      .addCase(postMailWinnerAsync.pending, (state, action) => {
        state.statusMessage = "pendingPostMailWinner";
        state.loading = true;
      })
      .addCase(postMailWinnerAsync.fulfilled, (state, action) => {
        state.statusMessage = "fulfilledPostMailWinner";
        state.loading = false;
      })
      .addCase(postMailWinnerAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedPostMailWinner";
        state.loading = false;
      })
      .addCase(patchConcursoAsync.pending, (state, action) => {
        state.statusMessage = "pendingPatchConcurso";
        state.loading = true;
      })
      .addCase(patchConcursoAsync.fulfilled, (state, action) => {
        state.statusMessage = "fulfilledPatchConcurso";
        state.loading = false;
      })
      .addCase(patchConcursoAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedPatchConcurso";
        state.loading = false;
      })
      .addCase(patchWinnerConcursoAsync.pending, (state, action) => {
        state.statusMessage = "pendingPatchWinner";
        state.loading = true;
      })
      .addCase(patchWinnerConcursoAsync.fulfilled, (state, action) => {
        state.statusMessage = "fulfilledPatchWinner";
        state.loading = false;
      })
      .addCase(patchWinnerConcursoAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedPatchWinner";
        state.loading = false;
      })

      .addCase(deleteConcursoAsync.pending, (state, action) => {
        state.statusMessage = "pendingDeleteConcurso";
        state.loading = true;
      })
      .addCase(deleteConcursoAsync.fulfilled, (state, action) => {
        state.statusMessage = "fulfilledDeleteConcurso";
        state.loading = false;
      })
      .addCase(deleteConcursoAsync.rejected, (state, action) => {
        state.statusMessage = "rejectedDeleteConcurso";
        state.loading = false;
      });
  },
});

export const {
  setRefreshStateConcursos,
  setNuevoConcurso,
  setRefreshNuevoConcurso,
  setStatusMessage,
} = concursoSlice.actions;

export default concursoSlice.reducer;
