import React from "react";
import "./modal.css";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Boton from "../Boton/Boton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ReactHtmlParser from "react-html-parser";
import "react-quill/dist/quill.snow.css"; // Include the styles
import { useSelector } from "react-redux";

const Modal = (props) => {
  const {
    titulo,
    info,
    type,
    codigo,
    codeRef,
    setOpenModal,
    handleCopy,
    setOpenModalConcurso,
    iconOk,
    concurso,
    onClick,
    fechConcurso,
    nombrePrograma,
    parrafo,
    imgConcurso,
    ganado,
    cambios,
    hiddenClose,
  } = props;

  const { statusSubscriptionToContest } = useSelector(
    (state) => state.concursoSlice
  );

  return (
    <section className="container-modal">
      {type === "beneficios" ? (
        <>
          <div className="box-modal-concurso">
            <div className="box-close-modal">
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenModalConcurso(false)}
              />
            </div>

            <div className="box-info-modal-concurso">
              {titulo && <h2>{titulo}</h2>}

              <div>
                <p>Finaliza el: {fechConcurso}</p>
                <p>{nombrePrograma}</p>
              </div>

              <p className="parrafo-infoadicional">{parrafo}</p>

              <img
                src={imgConcurso}
                alt="Concurso"
                className="img-modal-concurso"
              />

              {codigo ? (
                <p className="info-descuento-tarjeta-oyente">
                  ¡Código copiado!
                </p>
              ) : (
                <p
                  className="info-descuento-tarjeta-oyente"
                  ref={codeRef}
                  onClick={() => handleCopy(nombrePrograma)}
                >
                  {nombrePrograma} <ContentCopyIcon sx={{ fontSize: "12px" }} />
                </p>
              )}
            </div>
          </div>
        </>
      ) : concurso ? (
        <div className="box-modal-concurso">
          <div className="box-close-modal">
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setOpenModalConcurso(false)}
            />
          </div>

          <div className="box-info-modal-concurso">
            {titulo && <h2>{titulo}</h2>}

            <div>
              <p>{fechConcurso}</p>
              <p>{nombrePrograma}</p>
            </div>

            <div
              className="ql-editor parrafo-infoadicional"
              style={{ height: "300px" }}
            >
              {ReactHtmlParser(parrafo)}
            </div>

            <img
              src={imgConcurso}
              alt="Concurso"
              className="img-modal-concurso"
            />

            <Boton text={"Inscribirse"} type={2} onClick={onClick} />
          </div>
        </div>
      ) : (
        <div className="box-modal">
          <div className="box-close-modal">
            <CloseIcon
              sx={
                hiddenClose
                  ? { pointerEvents: "none", opacity: 0 }
                  : { cursor: "pointer" }
              }
              onClick={() => setOpenModal(false)}
            />
          </div>

          {cambios ? (
            <div className="box-info-modal">
              <div className="box-titulos-modal">
                <div>
                  <h2 className="h2-info-modal">¡Tus cambios han sido</h2>
                  <h2 className="h2-info-modal">guardados con éxito!</h2>
                </div>
              </div>
            </div>
          ) : (
            <div className="box-info-modal">
              <div className="box-titulos-modal">
                <div>
                  {ganado && <h2 className="h2-info-modal">¡Felicidades!</h2>}
                  <h2 className="h2-info-modal">
                    {ganado
                      ? "Ganaste el concurso"
                      : "Tu inscripción al concurso"}
                  </h2>
                  <h2 className="h2-info-modal">{titulo}</h2>
                  {!ganado &&
                    statusSubscriptionToContest ===
                    "fulfilledParticipanteConcurso" ? (
                    <h2 className="h2-info-modal">ha sido exitosa</h2>
                  ) : statusSubscriptionToContest ===
                    "rejectedParticipanteConcurso" ? (
                    <h2 className="h2-info-modal">tuvo un error</h2>
                  ) : (
                    <h2 className="h2-info-modal">...</h2>
                  )}
                </div>

                {iconOk &&
                  statusSubscriptionToContest ===
                  "fulfilledParticipanteConcurso" && (
                    <TaskAltIcon sx={{ color: "#db0d15" }} />
                  )}
              </div>

              {info && <p className="p-info-modal">{ReactHtmlParser(info)}</p>}
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Modal;
