/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./vistaBeneficios.css";
import Beneficio from "../../Beneficio/Beneficio";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AgregarBeneficio from "../../AgregarBeneficio/AgregarBeneficio";
import { useDispatch } from "react-redux";
import { getAllBeneficiosAsync } from "../../../app/silices/beneficio/beneficioThunk";
import { format } from "date-fns";
import { useSelector } from "react-redux";

const VistaBeneficios = (props) => {
  const { login, dataBeneficios } = props;
  const [agregar, setAgregar] = useState(false);
  const dispatch = useDispatch();
  const [getBenefit, setGetBenefit] = useState(false);
  const { role } = useSelector((state) => state.loginSlice);

  useEffect(() => {
    if (getBenefit === true) {
      dispatch(getAllBeneficiosAsync({ token: login.token }));
      setGetBenefit(false);
    }
  }, [getBenefit]);

  return (
    <section>
      <h2 className="titulo-vista-beneficios-admin">Beneficios</h2>

      {!agregar && (
        <>
          {role === "admin" || role === "comercial" ? (
            <div className="boton-agregar-concurso-admin">
              <button onClick={() => setAgregar(true)}>
                <AddBoxIcon />
                Agregar Beneficio
              </button>
            </div>
          ) : null}

          {dataBeneficios.beneficios.map((beneficio, index) => (
            <Beneficio
              key={index}
              img={`data:image/jpg;base64,${beneficio.image}`}
              titulo={beneficio.title}
              codigoDescuento={beneficio.discountCode}
              fechaFinalizacion={format(
                new Date(beneficio.endDate),
                "dd-MM-yyyy"
              )}
              info={beneficio.benefitUse}
              login={login}
              idBeneficio={beneficio.id}
            />
          ))}
        </>
      )}

      {agregar && (
        <AgregarBeneficio
          setAgregar={setAgregar}
          nuevoBeneficio={dataBeneficios.nuevoBeneficio}
          statusMessageBenefit={dataBeneficios.statusMessageBenefit}
          login={login}
          setGetBenefit={setGetBenefit}
        />
      )}
    </section>
  );
};

export default VistaBeneficios;
