/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./oyente.css";
import NavBarOyente from "../../components/NavBarOyente/NavBarOyente";
import EditPerfil from "../../components/EditPerfil/EditPerfil";
import HomeOyente from "../../components/HomeOyente/HomeOyente";
import OyenteMobile from "./OyenteMobile";
import { useSelector, useDispatch } from "react-redux";
import { getUserAsync } from "../../app/silices/usuarios/usuarioThunk";
import {
  setRefreshState,
  setStatusMessageLogin,
} from "../../app/silices/login/loginSlice";
import { setRefreshStateGoogle } from "../../app/silices/usuarios/usuarioGoogleSlice";
import { setRefreshStateUser } from "../../app/silices/usuarios/usuarioSlice";
import { redirectToNewPage } from "../../utils/functions";
import {
  getAllConcursosAsync,
  getConcursosOyenteAsync,
  getParticipantesEnConcursosAsync,
  getConcursosWinnersAsync,
} from "../../app/silices/concurso/concursoThunk";
import { getAllBeneficiosAsync } from "../../app/silices/beneficio/beneficioThunk";
import { setRefreshStateBeneficio } from "../../app/silices/beneficio/beneficioSlice";
import { setRefreshStateConcursos } from "../../app/silices/concurso/concursoSlice";
import { Audio } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Redirecciones from "../../components/RedireccionesContainer/Redirecciones";
import LogoutIcon from "@mui/icons-material/Logout";

const Oyente = () => {
  const [perfil, setPerfil] = useState(false);
  const [isScreenWidth600, setIsScreenWidth600] = useState(false);
  const login = useSelector((state) => state.loginSlice);
  const { profile, statusMessage } = useSelector((state) => state.usuarioSlice);
  const concursos = useSelector((state) => state.concursoSlice);
  const beneficios = useSelector((state) => state.beneficioSlice);
  const dispatch = useDispatch();
  const [bandera, setBandera] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;
      setIsScreenWidth600(width <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    dispatch(getUserAsync({ token: login.token, idUser: login.id }));

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      login.statusMessage === "rejectedToken" ||
      statusMessage === "rejectedLogin"
    ) {
      dispatch(setRefreshState());
      dispatch(setRefreshStateGoogle());
      dispatch(setRefreshStateUser());
      dispatch(setStatusMessageLogin(""));
      dispatch(setRefreshStateBeneficio());
      dispatch(setRefreshStateConcursos());
      redirectToNewPage("/");
    }
  }, [login.statusMessage, statusMessage]);

  useEffect(() => {
    dispatch(getAllConcursosAsync({ token: login.token }));
    dispatch(getParticipantesEnConcursosAsync({ token: login.token }));
    dispatch(getAllBeneficiosAsync({ token: login.token }));
    dispatch(
      getConcursosOyenteAsync({ token: login.token, idUsuario: profile.id })
    );
    dispatch(getConcursosWinnersAsync({ token: login.token }));
  }, []);

  useEffect(() => {
    if (profile.id !== "" && bandera === true) {
      dispatch(
        getConcursosOyenteAsync({ token: login.token, idUsuario: profile.id })
      );
      setBandera(false);
    }
  }, [profile.id, bandera]);

  const handleLogout = () => {
    setPerfil(false);
    dispatch(setRefreshState());
    dispatch(setRefreshStateGoogle());
    dispatch(setRefreshStateUser());
    dispatch(setRefreshStateBeneficio());
    dispatch(setRefreshStateConcursos());
    redirectToNewPage("/");
  };
  return (
    <section className="container-oyente">
      {statusMessage === "pending" || !profile.id ? (
        <div className="loader-all loader-oyente">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="red"
            ariaLabel="Cargando..."
          />
          <p>Cargando...</p>
        </div>
      ) : !profile.formatVerifiedAt && statusMessage === "fulfilled" ? (
        <>
          <Link
            to={"/"}
            className="opcion-nav-oyente opcion-nav-oyente-cerrasesion"
            onClick={handleLogout}
          >
            <LogoutIcon sx={{ fontSize: "18px" }} />
            Cerrar sesión
          </Link>
          <p>
            Por favor verifique su cuenta antes de continuar, puede utilizar el
            correo que mandamos a su email
          </p>
        </>
      ) : isScreenWidth600 ? (
        <>
          <OyenteMobile setPerfil={setPerfil} profile={profile} />
        </>
      ) : (
        <>
          <Redirecciones />

          <div className="box-oyente">
            <NavBarOyente
              perfil={perfil}
              setPerfil={setPerfil}
              profile={profile}
            />
          </div>

          {concursos.loadingConcursos || beneficios.loading ? (
            <div className="loader-all loader-oyente">
              <Audio
                height="80"
                width="80"
                radius="9"
                color="red"
                ariaLabel="Cargando..."
              />
              <p>Cargando...</p>
            </div>
          ) : (
            <div
              className={`box2-oyente ${perfil ? "box2-oyente-perfil-home" : ""
                }`}
            >
              {perfil ? (
                <EditPerfil
                  setPerfil={setPerfil}
                  profile={profile}
                  login={login}
                  statusMessage={statusMessage}
                />
              ) : (
                <HomeOyente
                  profile={profile}
                  setPerfil={setPerfil}
                  login={login}
                />
              )}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default Oyente;
