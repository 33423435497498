/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './adminMobile.css'
import VistaConcursos from '../../components/VistasAdmin/VistaConcursos/VistaConcursos';
import VistaUsuarios from '../../components/VistasAdmin/VistaUsuarios/VistaUsuarios';
import VistaEstadisticas from '../../components/VistasAdmin/VistaEstadisticas/VistaEstadisticas';
import VistaPerfil from '../../components/VistasAdmin/VistaPerfil/VistaPerfil';
import VistaBeneficios from '../../components/VistasAdmin/VistaBeneficios/VistaBeneficios';
import VistaNotas from '../../components/VistasAdmin/VistaNotas/VistaNotas';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUsersAsync } from '../../app/silices/usuarios/usuarioThunk';

//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdminMobile = (props) => {
    const { opcion, dataConcursos, newConcurso, setNewConcurso, programasConcursos, dataBeneficios, dataNotas } = props
    const login = useSelector(state => state.loginSlice)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllUsersAsync({ token: login.token }))
    }, [])

    return (
        <section>
            {
                opcion === 'concursos' &&
                <VistaConcursos login={login} dataConcursos={dataConcursos} newConcurso={newConcurso} setNewConcurso={setNewConcurso} />
            }

            {
                opcion === 'usuarios' &&
                <VistaUsuarios />
            }

            {
                opcion === 'estadisticas' &&
                <VistaEstadisticas programasConcursos={programasConcursos} dataConcursos={dataConcursos} />
            }

            {
                opcion === 'beneficios' &&
                <VistaBeneficios login={login} dataBeneficios={dataBeneficios} />
            }
            {
                opcion === "notas" &&
                <VistaNotas login={login} dataNotas={dataNotas} />
            }
            {
                opcion === 'perfil' &&
                <VistaPerfil />
            }

        </section>
    );
};

export default AdminMobile;