import { createSlice } from '@reduxjs/toolkit'
import { getAllNotasAsync, postNotaAsync, deleteNotaAsync } from './notaThunk'

const initialState = {
    notas: [],
    nuevaNota: {
        title: '',
        image: '',
        description: "",
        link: "",
    },
    statusMessageNote: '',
    loading: false,
}

export const notaSlice = createSlice({
    name: 'publinota',
    initialState,
    reducers: {
        setNuevaNota: (state, action) => {
            state.nuevaNota = { ...state.nuevaNota, ...action.payload }
        },
        setRefreshStateNota: () => initialState,
        setRefreshNuevaNota: (state) => {
            state.nuevaNota = {
                title: '',
                image: '',
                description: "",
                link: "",
            }
        },
        setStatusMessageNote: (state, action) => {
            state.statusMessageNote = action.payload
        }
    },

    extraReducers: builder => {
        builder
            .addCase(getAllNotasAsync.pending, (state, action) => {
                state.statusMessageNote = 'pendingAllNotas'
                state.loading = true
            })
            .addCase(getAllNotasAsync.fulfilled, (state, action) => {
                state.notas = action.payload.data.data
                state.statusMessageNote = 'fulfilledAllNotas'
                state.loading = false
            })
            .addCase(getAllNotasAsync.rejected, (state, action) => {
                state.statusMessageNote = 'rejectedAllNotas'
                state.loading = false
            })

            .addCase(postNotaAsync.pending, (state, action) => {
                state.statusMessageNote = 'pendingCreateNotas'
                state.loading = true
            })
            .addCase(postNotaAsync.fulfilled, (state, action) => {
                state.statusMessageNote = 'fulfilledCreateNotas'
                state.loading = false
            })
            .addCase(postNotaAsync.rejected, (state, action) => {
                state.statusMessageNote = 'rejectedCreateNotas'
                state.loading = false
            })

            .addCase(deleteNotaAsync.pending, (state, action) => {
                state.statusMessageNote = 'pendingDeleteNotas'
                state.loading = true
            })
            .addCase(deleteNotaAsync.fulfilled, (state, action) => {
                state.statusMessageNote = 'fulfilledDeleteNotas'
                state.loading = false
            })
            .addCase(deleteNotaAsync.rejected, (state, action) => {
                state.statusMessageNote = 'rejectedDeleteNotas'
                state.loading = false
            })
    }
})

export const {
    setNuevaNota,
    setRefreshStateNota,
    setRefreshNuevaNota,
    setStatusMessageNote
} = notaSlice.actions

export default notaSlice.reducer