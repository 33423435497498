/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./admin.css";
import NavBarAdmin from "../../components/NavBarAdmin/NavBarAdmin";
import VistasAdmin from "../../components/VistasAdmin/VistasAdmin";
import AdminMobile from "../AdminMobile/AdminMobile";
import NavBarAdminMobile from "../AdminMobile/NavBarAdminMobile";
import { useSelector, useDispatch } from "react-redux";
import {
  setRefreshState,
  setStatusMessageLogin,
} from "../../app/silices/login/loginSlice";
import { setRefreshStateGoogle } from "../../app/silices/usuarios/usuarioGoogleSlice";
import { setRefreshStateUser } from "../../app/silices/usuarios/usuarioSlice";
import { redirectToNewPage } from "../../utils/functions";
import { getUserAsync } from "../../app/silices/usuarios/usuarioThunk";
import { getAllBeneficiosAsync } from "../../app/silices/beneficio/beneficioThunk";
import {
  getParticipantesEnConcursosAsync,
  getAllConcursosAsync,
  getConcursosWinnersAsync,
} from "../../app/silices/concurso/concursoThunk";
import {
  setRefreshStateConcursos,
  setStatusMessage,
} from "../../app/silices/concurso/concursoSlice";
import { Audio } from "react-loader-spinner";
import { setRefreshStateBeneficio } from "../../app/silices/beneficio/beneficioSlice";
import { setRefreshStateNota } from "../../app/silices/notas/notaSlice";
import { getAllUsersAsync } from "../../app/silices/usuarios/usuarioThunk";
import { getAllNotasAsync } from "../../app/silices/notas/notaThunk";
const Admin = () => {
  const [opcion, setOpcion] = useState("concursos");
  const { statusMessage, token, id } = useSelector((state) => state.loginSlice);
  const user = useSelector((state) => state.usuarioSlice);
  const dataConcursos = useSelector((state) => state.concursoSlice);
  const dataBeneficios = useSelector((state) => state.beneficioSlice);
  const dataNotas = useSelector((state) => (state.notaSlice));
  const dispatch = useDispatch();
  const [newConcurso, setNewConcurso] = useState("");
  const [programas, setProgramas] = useState([]);
  const [isScreenWidth600, setIsScreenWidth600] = useState(false);




  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;
      setIsScreenWidth600(width <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(getUserAsync({ token, idUser: id }));
    dispatch(getParticipantesEnConcursosAsync({ token }));
    dispatch(getAllConcursosAsync({ token }));
    dispatch(getConcursosWinnersAsync({ token }));
    dispatch(getAllBeneficiosAsync({ token }));
    dispatch(getAllUsersAsync({ token }));
    dispatch(getAllNotasAsync({ token }))

    statsPrograma();
  }, []);

  useEffect(() => {
    if (
      statusMessage === "rejectedToken" ||
      user.statusMessage === "rejectedLogin"
    ) {
      dispatch(setRefreshState());
      dispatch(setRefreshStateGoogle());
      dispatch(setRefreshStateUser());
      dispatch(setStatusMessageLogin(""));
      dispatch(setRefreshStateBeneficio());
      dispatch(setRefreshStateNota());
      dispatch(setRefreshStateConcursos());
      redirectToNewPage("/");
    }
  }, [statusMessage, user.statusMessage]);

  useEffect(() => {
    if (
      dataConcursos.statusMessage === "fulfilledPostConcurso"
      // || dataConcursos.statusMessage === 'fulfilledPatchWinner'
    ) {
      setTimeout(() => {
        dispatch(getAllConcursosAsync({ token: token }));
        dispatch(getConcursosWinnersAsync({ token: token }));
        dispatch(getParticipantesEnConcursosAsync({ token: token }));
        dispatch(setStatusMessage(""));
        setNewConcurso("");
      }, 2000);
    }

    if (
      dataConcursos.statusMessage === "fulfilledPostConcurso"
      // || dataConcursos.statusMessage === 'fulfilledPatchWinner'
    ) {
      dispatch(getParticipantesEnConcursosAsync({ token: token }));
      statsPrograma();
    }
  }, [dataConcursos.statusMessage]);

  const statsPrograma = () => {
    const data = [];

    dataConcursos.concursos.forEach((concurso) => {
      const filter = dataConcursos.cantidadParticipantes?.filter(
        (data) => data.contestName === concurso.title
      );

      if (filter.length !== 0) {
        data.push({ name: concurso.program, Cantidad: filter[0].participants });
      }
    });
    setProgramas(data);
  };

  return (
    <main className="container-admin">
      {isScreenWidth600 ? (
        <>
          <section className="box-section-nav-admin">
            <NavBarAdminMobile opcion={opcion} setOpcion={setOpcion} />
          </section>

          <section
            className={`${opcion
              ? "box-section-vistas-admin-mobile"
              : "hidden-element-nav-admin"
              }`}
          >
            {dataConcursos.loadingConcursos ? (
              <div className="loader-all loader-admin">
                <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="red"
                  ariaLabel="Cargando..."
                />
                <p>Cargando...</p>
              </div>
            ) : (
              <AdminMobile
                opcion={opcion}
                dataConcursos={dataConcursos}
                newConcurso={newConcurso}
                setNewConcurso={setNewConcurso}
                programasConcursos={programas}
                dataBeneficios={dataBeneficios}
                dataNotas={dataNotas}
              />
            )}
          </section>
        </>
      ) : (
        <>
          <section className="box-section-nav-admin">
            <NavBarAdmin opcion={opcion} setOpcion={setOpcion} />
          </section>

          <section className="box-section-vistas-admin">
            {dataConcursos.loadingConcursos ? (
              <div className="loader-all loader-admin">
                <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="red"
                  ariaLabel="Cargando..."
                />
                <p>Cargando...</p>
              </div>
            ) : (
              <VistasAdmin
                opcion={opcion}
                dataConcursos={dataConcursos}
                newConcurso={newConcurso}
                setNewConcurso={setNewConcurso}
                programasConcursos={programas}
                dataBeneficios={dataBeneficios}
                dataNotas={dataNotas}
              />
            )}
          </section>
        </>
      )}
    </main>
  );
};

export default Admin;
