/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "./vistaConcursos.css";
import NavBarConcursos from "./NavBarConcursos/NavBarConcursos";
import TablaConcursos from "./TablaConcursos/TablaConcursos";
import Concurso from "../../Concurso/Concurso";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AgregarConcurso from "../../AgregarConcurso/AgregarConcurso";
import EditConcurso from "../../EditConcurso/EditConcurso";
import { useSelector } from "react-redux";
import { Audio } from "react-loader-spinner";
import { format } from "date-fns";

const VistaConcursos = (props) => {
  const { login, dataConcursos, newConcurso, setNewConcurso } = props;
  const [opcionNav, setOpcionNav] = useState("vigentes");
  const [agregar, setAgregar] = useState(false);
  const { role } = useSelector((state) => state.loginSlice);

  const filterActivos = dataConcursos.concursos.filter(
    (concurso) => concurso.contestState === "PENDIENTE"
  );
  const filterSeleccionables = filterActivos.filter(
    (concurso) =>
      new Date().toISOString().split("T")[0] > concurso.endDate.split("T")[0]
  );
  const filterNoSeleccionables = filterActivos.filter(
    (concurso) =>
      new Date().toISOString().split("T")[0] <= concurso.endDate.split("T")[0]
  );
  const concursosVigentes = [
    ...filterSeleccionables,
    ...filterNoSeleccionables,
  ];
  concursosVigentes.sort(function (a, b) {
    if (a.stars > b.stars) {
      return -1;
    }
    if (a.stars < b.stars) {
      return 1;
    }
    var dateA = new Date(a.createdAt),
      dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  const [edit, setEdit] = useState(false);
  const [idConcurso, setIdConcurso] = useState("");

  const handlerTitulo = () => {
    if (opcionNav === "vigentes" || opcionNav === "agregar")
      return "Concursos vigentes";
    if (opcionNav === "finalizados") return "Concursos finalizados";
    if (opcionNav === "ganadores") return "Ganadores";
  };

  const handleEdit = (concursoId) => {
    setEdit(true);

    setIdConcurso(concursoId);
  };

  const contestWinnersSorted = [...dataConcursos.ganadores].sort(
    (a, b) => new Date(b.endDate) - new Date(a.endDate)
  );

  return (
    <section>
      <NavBarConcursos opcion={opcionNav} setOpcion={setOpcionNav} />

      <h2 className="titulo-vista-concursos-admin">{handlerTitulo()}</h2>

      {opcionNav === "vigentes" && agregar === false && edit === false && (
        <>
          {role === "admin" || role === "comercial" ? (
            <div className="boton-agregar-concurso-admin">
              <button onClick={() => setAgregar(true)}>
                <AddBoxIcon />
                Agregar Concurso
              </button>
            </div>
          ) : (
            <></>
          )}

          {concursosVigentes.map((concurso, index) => (
            <Concurso
              key={index}
              idConcurso={concurso.id}
              img={concurso.image}
              titulo={concurso.title}
              programa={concurso.program}
              fechaFinalizacion={concurso.endDate}
              fechaInicio={concurso.beginDate}
              stars={concurso.stars}
              info={concurso.aditionalInformation}
              anunciante={concurso.advertiser}
              imgBanner={concurso.bannerImage}
              tipo={"concurso"}
              login={login}
              statusMessage={dataConcursos.statusMessage}
              concursos={dataConcursos.concursos}
              ganadores={dataConcursos.ganadores}
              cantidadParticipantes={dataConcursos.cantidadParticipantes}
              newConcurso={newConcurso}
              setNewConcurso={setNewConcurso}
              onEdit={handleEdit}
            />
          ))}
        </>
      )}

      {opcionNav === "vigentes" && agregar === true && (
        <AgregarConcurso
          newConcurso={newConcurso}
          setNewConcurso={setNewConcurso}
          setAgregar={setAgregar}
          login={login}
        />
      )}

      {opcionNav === "vigentes" && edit === true && (
        <EditConcurso
          concurso={concursosVigentes?.find(
            (concurso) => concurso.id === idConcurso
          )}
          idConcurso={idConcurso}
          newConcurso={newConcurso}
          setNewConcurso={setNewConcurso}
          setEdit={setEdit}
          login={login}
        />
      )}

      {dataConcursos.statusMessage === "pendingAllConcursos" ? (
        <div className="loader-all box-loader">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="red"
            ariaLabel="Cargando..."
          />
          <p>Cargando...</p>
        </div>
      ) : (
        opcionNav === "finalizados" && (
          <TablaConcursos
            columna1={"Concurso"}
            columna2={"Finalización"}
            columna3={"Anunciante"}
            columna4={"Ganador"}
            arrayInfo={contestWinnersSorted}
            infoColumna4None={true}
          />
        )
      )}

      {dataConcursos.statusMessage === "pendingAllConcursos" ? (
        <div className="loader-all box-loader">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="red"
            ariaLabel="Cargando..."
          />
          <p>Cargando...</p>
        </div>
      ) : (
        opcionNav === "ganadores" && (
          <section>
            <table className="container-table-vistas-admin">
              <thead>
                <tr className="header-table-vistas">
                  <th>Nombre y Apellido</th>
                  <th>DNI</th>
                  <th>Concurso</th>
                  <th className={"hidden-boder-right"}>Finalización</th>
                </tr>
              </thead>

              <thead className="header-table-vistas-hidden">
                <tr>
                  <th>Nombre y Apellido</th>
                  <th>DNI</th>

                  <th>Concurso</th>
                  <th>Finalización</th>
                </tr>
              </thead>

              <thead className="header-table-vistas-hidden">
                <tr>
                  <th>Nombre y Apellido</th>
                  <th>DNI</th>

                  <th>Concurso</th>
                  <th>Finalización</th>
                </tr>
              </thead>

              <tbody>
                {contestWinnersSorted.map((info, index) => (
                  <tr className="body-table-vistas" key={index}>
                    <td>{info.fullName}</td>
                    <td>{info.dni}</td>
                    <td>{info.title}</td>
                    <td>{format(new Date(info.endDate), "dd-MM-yyyy")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        )
      )}
    </section>
  );
};

export default VistaConcursos;
