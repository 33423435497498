export const BACK_URL = process.env.REACT_APP_URL_BACKEND
export const CLIENT_ID_GOOGLE = process.env.REACT_APP_CLIENT_ID
export const DATA_GOOGLE = process.env.REACT_APP_GOOGLE
export const opcionesGenero = ['Femenino', 'Masculino', 'Prefiero no decirlo']
export const opcionesOcupacion = [
    'Abogado',
    'Administrador de empresas',
    'Ama de casa',
    'Analista financiero',
    'Arquitecto',
    'Asistente de gerencia',
    'Chef o cocinero',
    'Chofer de transporte',
    'Consultor de negocios',
    'Contador público',
    'Diseñador',
    'Docente',
    'Ejecutivo de ventas',
    'Empleado bancario',
    'Empleado de comercio',
    'Empleado de escribanía',
    'Empleado público',
    'Escribano',
    'Farmacéutico',
    'Ingeniero',
    'Marketing y Publicidad',
    'Médico',
    'Operario de fábrica',
    'Otra',
    'Periodista',
    'Personal de limpieza',
    'Profesor o maestro',
    'Programador informático',
    'Psicólogo',
    'Recursos humanos',
    'Seguridad',
    'Técnico en informática',
    'Trabajador administrativo',
    'Trabajador de la construcción',
    'Trabajador de la salud',
    'Trabajador social',
    'Veterinario'
]
