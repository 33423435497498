import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteNota, getAllNotas, postNota } from "./notaApi";

export const getAllNotasAsync = createAsyncThunk(
    'notes/getAllNotas',
    async (args) => {
        const { token } = args
        const response = await getAllNotas(token);
        return response;
    }
);

export const postNotaAsync = createAsyncThunk(
    'notes/postNota',
    async (args) => {
        const { token, body } = args
        const response = await postNota(token, body);
        return response;
    }
);

export const deleteNotaAsync = createAsyncThunk(
    'notes/deleteNota',
    async (args) => {
        const { token, id } = args
        const response = await deleteNota(token, id);
        return response;
    }
);