import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { useState } from "react";
import "./TableContainer.css"; // Import the CSS

export default function TableEstadisticas({ data }) {
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedData = [...data].sort((a, b) => {
    let valueA = a[sortField];
    let valueB = b[sortField];
    let comparison = 0;

    if (sortField === "beginDate" || sortField === "endDate") {
      const datePartsA = valueA.split("/").map((x) => parseInt(x, 10));
      const datePartsB = valueB.split("/").map((x) => parseInt(x, 10));
      valueA = new Date(datePartsA[2], datePartsA[1] - 1, datePartsA[0]);
      valueB = new Date(datePartsB[2], datePartsB[1] - 1, datePartsB[0]);
    }

    if (valueA < valueB) {
      comparison = -1;
    } else if (valueA > valueB) {
      comparison = 1;
    }

    return sortDirection === "asc" ? comparison : -comparison;
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort("title")}>
              <TableSortLabel
                active={sortField === "title"}
                direction={sortDirection}
              >
                Title
              </TableSortLabel>
            </TableCell>
            <TableCell onClick={() => handleSort("cantidad_concursos")}>
              <TableSortLabel
                active={sortField === "cantidad_concursos"}
                direction={sortDirection}
              >
                Cantidad Concursos
              </TableSortLabel>
            </TableCell>
            <TableCell onClick={() => handleSort("cantidad_participantes")}>
              <TableSortLabel
                active={sortField === "cantidad_participantes"}
                direction={sortDirection}
              >
                Cantidad Participantes
              </TableSortLabel>
            </TableCell>
            <TableCell onClick={() => handleSort("beginDate")}>
              <TableSortLabel
                active={sortField === "beginDate"}
                direction={sortDirection}
              >
                Fecha Inicio
              </TableSortLabel>
            </TableCell>
            <TableCell onClick={() => handleSort("endDate")}>
              <TableSortLabel
                active={sortField === "endDate"}
                direction={sortDirection}
              >
                Fecha Fin
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => (
            <React.Fragment key={row.id}>
              <TableRow>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.cantidad_concursos}</TableCell>
                <TableCell>{row.cantidad_participantes}</TableCell>
                <TableCell>{row.beginDate}</TableCell>
                <TableCell>{row.endDate}</TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
