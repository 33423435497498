import React from "react";
import "./vistaUsuarios.css";
import { useSelector } from "react-redux";
import GetAppIcon from "@mui/icons-material/GetApp";

const VistaUsuarios = () => {
  const { allUsuarios } = useSelector((state) => state.usuarioSlice);

  function transformData() {
    return allUsuarios.map((item) => ({
      fullName: item.fullName || "",
      dni: item.dni || "",
      email: item.email || "",
      birthDay: item.birthDay
        ? new Date(item.birthDay).toISOString().split("T")[0]
        : "", // Format birthday to only date
      genre: item.genre || "",
      profession: item.profession || "",
      city: item.city || "",
      province: item.province || "",
      phoneNumber: item.phoneNumber || "",
      verified: item.verifiedAt ? "YES" : "NO", // Check if verified
    }));
  }

  function convertToCSV(data) {
    const header = [
      "fullName",
      "dni",
      "email",
      "birthday",
      "genre",
      "profession",
      "city",
      "province",
      "phoneNumber",
      "verified",
    ].join(",");
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return `${header}\n${rows}`;
  }

  function downloadCSV(data) {
    const transformedData = transformData(data);
    const csvData = convertToCSV(transformedData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.style.display = "none";
    link.download = "usersData.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <section>
      <h2 className="titulo-vista-usuarios-admin">Usuarios</h2>
      <div className="download-button-container">
        <button onClick={downloadCSV} className="download-button">
          <GetAppIcon />
          CSV
        </button>
      </div>

      <section>
        <table className="container-table-vistas-admin">
          <thead>
            <tr className="header-table-vistas">
              <th>Nombre y Apellido</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>DNI</th>
              <th>Ciudad</th>
            </tr>
          </thead>

          <thead className="header-table-vistas-hidden">
            <tr>
              <th>Nombre y Apellido</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>DNI</th>
              <th>Ciudad</th>
            </tr>
          </thead>

          <thead className="header-table-vistas-hidden">
            <tr>
              <th>Nombre y Apellido</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>DNI</th>
              <th>Ciudad</th>
            </tr>
          </thead>

          <tbody>
            {allUsuarios.map((info, index) => (
              <tr className="body-table-vistas" key={index}>
                <td>{info.fullName}</td>
                <td>{info.email}</td>
                <td>{info.phoneNumber}</td>
                <td>{info.dni}</td>
                <td>{info.city}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </section>
  );
};

export default VistaUsuarios;
