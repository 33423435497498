import React from 'react'
import "./nota.css"
const logo = require("../../assets/logo-lu5.png")

export const Nota = ({ title, image, description, link }) => {
    return (
        <a href={link} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
            <div className='publinota-container-v2'>
                <div className="publinota-title-section-v2">
                    <h3>{title}</h3>
                    <span className='publinota-description-v2'>
                        {description}
                    </span>
                </div>

                <div className='paralelogramo-v2'>
                    <img className="publinota-logo-section-v2" src={logo} alt="Logo lu5 Club del oyente" />
                </div>

                <div className="publinota-image-section-v2">
                    <img className='img-v2' src={`data:image/jpg;base64,${image}`} alt="img" />
                </div>
            </div>
        </a >
    )
}
