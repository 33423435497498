import React, { useState } from "react";
import "./concurso.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Rating from "@mui/material/Rating";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConcursoAsync,
  patchConcursoStarsAsync,
  patchWinnerConcursoAsync,
  getAllConcursosAsync,
  getConcursosWinnersAsync,
  getParticipantesEnConcursosAsync,
} from "../../app/silices/concurso/concursoThunk";
import Boton from "../Boton/Boton";
import { format } from "date-fns";
import { Audio } from "react-loader-spinner";
import isAfter from "date-fns/isAfter";
import ReactHtmlParser from "react-html-parser";
import "react-quill/dist/quill.snow.css"; // Include the styles

const Concurso = (props) => {
  const {
    anunciante,
    cantidadParticipantes,
    concursos,
    fechaFinalizacion,
    fechaInicio,
    ganadores,
    idConcurso,
    img,
    imgBanner,
    info,
    login,
    newConcurso,
    programa,
    setNewConcurso,
    stars,
    statusMessage,
    titulo,
  } = props;
  const [eliminar, setEliminar] = useState(false);
  const [concursoStars, setConcursoStars] = useState(stars);
  const [winnersContest, setWinnersContest] = useState("");
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.loginSlice);

  const concursoSlect = concursos?.find(
    (concurso) => concurso.id === idConcurso
  );
  const winner = ganadores?.find(
    (ganador) => ganador.contestId === concursoSlect.id
  );
  const participantes = cantidadParticipantes?.find(
    (participante) => participante.contestId === idConcurso
  )?.participants;
  const hanlderDelete = () => {
    dispatch(deleteConcursoAsync({ token: login.token, idConcurso }));
    dispatch(getAllConcursosAsync({ token: login.token }));
    dispatch(getConcursosWinnersAsync({ token: login.token }));
    dispatch(getParticipantesEnConcursosAsync({ token: login.token }));
    setEliminar(true);
  };

  const handleSelectWinner = async () => {
    setNewConcurso(idConcurso);
    let data = await dispatch(
      patchWinnerConcursoAsync({ idConcurso, token: login.token })
    );
    data = data.payload.data.data;

    const winners = data.map((user) => user.fullName).join(" ");
    setWinnersContest(winners);
  };

  const handleConcursoStars = async (stars) => {
    stars = stars ? stars : 0;
    const body = {
      idConcurso: idConcurso,
      stars: stars,
    };
    await dispatch(patchConcursoStarsAsync({ body, token: login.token }));
  };

  return (
    <section
      className={`${eliminar
          ? "container-beneficio-concurso-hidden"
          : "container-concurso-completo"
        }`}
    >
      <section
        className={`container-beneficio-concurso ${eliminar ? "container-beneficio-concurso-hidden" : ""
          }`}
      >
        <div className="boxsuperior-beneficio-concurso">
          {role === "admin" || role === "comercial" ? (
            <Rating
              name="Stars"
              value={concursoStars}
              max={4}
              onChange={(event, newValue) => {
                setConcursoStars(newValue);
                handleConcursoStars(newValue);
              }}
            ></Rating>
          ) : (
            <></>
          )}
          {role === "admin" || role === "comercial" ? (
            <div className="botones-concurso">
              {
                <button
                  className="boton-editar-concurso"
                  onClick={() => props.onEdit(props.idConcurso)}
                >
                  <EditIcon />
                  Edit
                </button>
              }
              {(participantes === 0 || participantes === undefined) && (
                <button
                  className="boton-eliminar-beneficio-concurso"
                  onClick={hanlderDelete}
                >
                  <DeleteForeverIcon />
                  Eliminar
                </button>
              )}
            </div>
          ) : (
            <></>
          )}
          <img
            src={`data:image/jpg;base64,${img}`}
            alt="Concurso / Beneficio LU5"
            className="img-principal-beneficio-concurso"
          />
          <div className="box-beneficio-concurso">
            <div>
              <h2 className="titulo-beneficio-concurso">{titulo}</h2>

              <div className="inbox-beneficio-concurso">
                <span className="span-beneficio-concurso">
                  Programa que sortea:
                </span>
                <p className="parrafo-beneficio-concurso">{programa}</p>
              </div>

              <div>
                <span className="span-beneficio-concurso">
                  Fecha de inicio:
                </span>
                <p className="parrafo-beneficio-concurso">
                  {format(new Date(fechaInicio), "dd-MM-yyyy")}
                </p>
              </div>

              <div>
                <span className="span-beneficio-concurso">
                  Fecha de finalización:
                </span>
                <p className="parrafo-beneficio-concurso">
                  {format(new Date(fechaFinalizacion), "dd-MM-yyyy")}
                </p>
              </div>
            </div>
          </div>

          <div className="inbox2-beneficio-concurso ">
            <h2 className="titulo-beneficio-concurso">Info Adicional:</h2>
            <div className="ql-editor">{ReactHtmlParser(info)}</div>
          </div>
        </div>

        <div className="boxsuperior2-beneficio-concurso">
          <div>
            <span className="span-beneficio-concurso">Anunciante:</span>
            <p className="parrafo-beneficio-concurso">{anunciante}</p>
          </div>

          <div>
            <h2 className="titulo-beneficio-concurso">Banner anunciante</h2>
            <img
              src={`data:image/jpg;base64,${imgBanner}`}
              alt="Concurso / Beneficio LU5"
              className="img-banner-beneficio-concurso"
            />
          </div>
        </div>
      </section>
      {role === "admin" || role === "productor" ? (
        <div className="selector-ganador-concurso">
          {winner?.fullName === "" || winner === undefined ? (
            statusMessage === "pendingPatchWinner" &&
              newConcurso === idConcurso ? (
              <div className="loader-all">
                <Audio
                  height="80"
                  width="80"
                  radius="9"
                  color="red"
                  ariaLabel="Cargando..."
                />
                <p>Cargando...</p>
              </div>
            ) : statusMessage === "fulfilledPatchWinner" &&
              newConcurso === idConcurso ? (
              <>
                <span className="span-ok-registro">
                  ¡Ganador seleccionado correctamente!
                </span>
                <h2 className="ganador-concurso-text">
                  El ganador es: <span>{winnersContest}</span>
                </h2>
              </>
            ) : statusMessage === "rejectedPatchWinner" ? (
              <span className="span-error-registro">
                ¡Error al seleccionar el ganador!
              </span>
            ) : (
              <Boton
                type={
                  isAfter(
                    new Date().setHours(23, 59, 59, 0),
                    new Date(fechaFinalizacion)
                  )
                    ? 2
                    : 3
                }
                text={"Seleccionar ganador aleatorio"}
                onClick={handleSelectWinner}
              />
            )
          ) : (
            <>Error</>
          )}
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};

export default Concurso;
