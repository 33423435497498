import axios from 'axios'
import { BACK_URL } from '../../../constants/index'

export const getAllNotas = (token) => {
    return new Promise((resolve, reject) => {
        return axios({
            url: `${BACK_URL}/api/v1/notes`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
        })
            .then(response => response)
            .then(json => {

                return resolve({
                    data: json.data

                });
            })
            .catch(err => {
                return reject(err.response)
            });
    });
};

export const postNota = (token, body) => {
    const data = {
        title: body.title,
        image: body.image,
        description: body.description,
        link: body.link
    }

    return new Promise((resolve, reject) => {
        return axios({
            url: `${BACK_URL}/api/v1/notes`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
            data: data
        })
            .then(response => response)
            .then(json => {
                return resolve({
                    data: json.data
                });
            })
            .catch(err => {
                return reject(err.response)
            });
    });
};

export const deleteNota = (token, id) => {
    return new Promise((resolve, reject) => {
        return axios({
            url: `${BACK_URL}/api/v1/notes/${id}`,
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            },
        })
            .then(response => response)
            .then(json => {
                return resolve({
                    data: json.data
                });
            })
            .catch(err => {
                return reject(err.response)
            });
    });
};