/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./vistaNotas.css";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useDispatch, useSelector } from "react-redux";
import AgregarNota from "../../AgregarNota/AgregarNota";
import { getAllNotasAsync } from "../../../app/silices/notas/notaThunk";
import NotaAdmin from "../../Nota/NotaAdmin";

const VistaNotas = (props) => {
    const { login } = props;
    const [agregar, setAgregar] = useState(false);
    const dispatch = useDispatch();
    const [getNota, setGetNota] = useState(false);
    const { role } = login;
    const dataNotas = useSelector((state) => (state.notaSlice));

    useEffect(() => {
        dispatch(getAllNotasAsync({ token: login.token }))

        return () => { }
    }, [])


    return (
        <section>
            <h2 className="titulo-vista-beneficios-admin">Notas</h2>

            {!agregar && (
                <>
                    {role === "admin" || role === "comercial" ? (
                        <div className="boton-agregar-concurso-admin">
                            <button onClick={() => setAgregar(true)}>
                                <AddBoxIcon />
                                Agregar Notas
                            </button>
                        </div>
                    ) : null}

                </>
            )}

            {agregar ? (
                <AgregarNota
                    setAgregar={setAgregar}
                    nuevaNota={dataNotas.nuevaNota}
                    statusMessageNote={dataNotas.statusMessageNote}
                    login={login}
                    setGetNote={setGetNota}
                />) : null}

            {!agregar ?
                dataNotas.notas.map((nota) =>
                    <NotaAdmin
                        {...nota}
                        key={nota.id}
                        id={nota.id}
                    />
                ) : null
            }

        </section>
    );
};

export default VistaNotas;
