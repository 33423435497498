/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "../../assets/logo-lu5.svg";
import Boton from "../../components/Boton/Boton";
import Input from "../../components/Input/Input";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./inicioSesion.css";
import useCustomGoogleLogin from "../../hooks/useGoogleLogin";
import { useDispatch, useSelector } from "react-redux";
import {
  setStatusMessageLogin,
  setRedirectLogin,
} from "../../app/silices/login/loginSlice";
import { postLoginAsync } from "../../app/silices/login/loginThunk";
import { getUserAsync } from "../../app/silices/usuarios/usuarioThunk";
import { redirectToNewPage } from "../../utils/functions";

const InicioSesion = () => {
  const { googleLogin } = useCustomGoogleLogin();
  const dispatch = useDispatch();
  const { statusMessage, token, id, redirect } = useSelector(
    (state) => state.loginSlice
  );
  const [data, setData] = useState({ email: "", password: "" });
  const [vacio, setVacio] = useState(false);
  const [isScreenWidth600, setIsScreenWidth600] = useState(false);

  const isFacebookApp = () => {
    return navigator.userAgent.match(/FBAN|FBAV/i);
  };

  console.log(isFacebookApp());
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;
      setIsScreenWidth600(width <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    dispatch(setStatusMessageLogin(""));

    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  useEffect(() => {
    if (statusMessage === "rejected" && redirect) {
      setTimeout(() => {
        dispatch(setStatusMessageLogin(""));
        dispatch(setRedirectLogin(false));
      }, 6000);
    }

    if (statusMessage === "rejectedGoogle") {
      setTimeout(() => {
        redirectToNewPage("/registroGoogle");
        dispatch(setStatusMessageLogin(""));
      }, 3000);
    }
  }, [statusMessage, redirect, dispatch]);

  useEffect(() => {
    if (token.length > 0 && id.length > 0) {
      dispatch(getUserAsync({ token: token, idUser: id }));
    }
  }, [token, id, dispatch]);

  const handleChangeEmail = (event) => {
    setData({ ...data, email: event.target.value });
  };

  const handleChangePassword = (event) => {
    setData({ ...data, password: event.target.value });
  };

  const handleLogin = () => {
    if (data.email.length === 0 || data.password.length === 0) {
      return setVacio(true);
    }

    dispatch(
      postLoginAsync({
        email: data.email,
        password: data.password,
        google: false,
      })
    );
    setVacio(false);
  };

  const handleOpenInBrowser = () => {
    window.open(window.location.href, "_system");
  };

  return (
    <section className="container-sesion">
      <div className="main-sesion">
        <div className="header-sesion">
          <img src={logo} alt="Logo LU5" className="logo-sesion" />
        </div>

        <h2 className="titulo-seccion">Iniciar sesión</h2>

        {isScreenWidth600 && (
          <Link
            to={"/"}
            className="volver-registro volver-registro-mobile volver-registro-mobile2"
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "14px" }} />{" "}
            <p>Página principal</p>
          </Link>
        )}

        <section className="seccion-sesion">
          <form className="inputs-seccion distancia-botones-inicio-sesion">
            <Input
              type={"email"}
              placeholder={"Correo electrónico"}
              color
              required={true}
              onChange={handleChangeEmail}
            />
            <Input
              type={"password"}
              placeholder={"Contraseña"}
              color
              required={true}
              onChange={handleChangePassword}
            />
            <div className="link-pwd-container">
              <Link to={"/solicitudClave"} className="link-pwd">
                Olvidé mi contraseña
              </Link>
            </div>
          </form>

          {vacio && (
            <span
              className={
                "span-error-inicio-sesion span-error-inicio-sesion-view"
              }
            >
              ¡Datos incompletos!
            </span>
          )}

          {statusMessage === "rejected" && redirect && (
            <span
              className={
                "span-error-inicio-sesion span-error-inicio-sesion-view"
              }
            >
              ¡El email o la contraseña son incorrectos!
            </span>
          )}

          {statusMessage === "rejectedGoogle" && (
            <span
              className={
                "span-error-inicio-sesion span-error-inicio-sesion-view"
              }
            >
              ¡El usuario de google no está registrado!
            </span>
          )}

          <div className="btns1-seccion">
            <Boton text={"Iniciar sesión"} type={2} onClick={handleLogin} />
            {isFacebookApp() ? (
              <div>
                <p>
                  Para iniciar sesión con Google, presione el botón "..." en la
                  esquina superior de su pantalla y seleccione la opción "Abrir
                  en un navegador externo"
                </p>
              </div>
            ) : (
              <Boton
                iconGoogle={true}
                text={"Iniciar sesión con Google"}
                onClick={() => googleLogin()}
              />
            )}

            {!isScreenWidth600 && (
              <Link
                to={"/"}
                onClick={() => {
                  dispatch(setStatusMessageLogin(""));
                }}
                className="volver-registro"
              >
                <ArrowBackIosNewIcon sx={{ fontSize: "14px" }} />{" "}
                <p>Página principal</p>
              </Link>
            )}
          </div>
        </section>
      </div>
    </section>
  );
};

export default InicioSesion;
