/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import "./tarjetaOyente.css";
import Boton from "../Boton/Boton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Modal from "../Modal/Modal";
import { postParticipanteConcursoAsync } from "../../app/silices/concurso/concursoThunk";
import { useDispatch } from "react-redux";

const TarjetaOyente = (props) => {
  const {
    codigoDescuento,
    concursosOyente,
    estadoMiSorteo,
    estadoSorteo,
    fechaFinBeneficio,
    fechaParticipacion,
    finalizacionConcurso,
    ganadores,
    idConcurso,
    img,
    imgModalConcurso,
    infoModal,
    login,
    nombrePrograma,
    parrafo,
    programaSorteo,
    resultadoMiSorteo,
    tipo,
    titulo,
    usoBeneficio,
  } = props;

  const [codigo, setCodigo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalConcurso, setOpenModalConcurso] = useState(false);
  const [openModalBeneficio, setOpenModalBeneficio] = useState(false);
  const [isScreenWidth600, setIsScreenWidth600] = useState(false);
  const dispatch = useDispatch();
  const findConcurso = concursosOyente?.find((data) => data.id === idConcurso);
  const ganador = ganadores?.find((data) => data.title === titulo);
  const textRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth: width } = window;
      setIsScreenWidth600(width <= 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (openModal === true) {
      setTimeout(() => {
        setOpenModal(false);
        window.location.reload();
      }, 2500);
    }
  }, [openModal]);

  const handleCopy = (text) => {
    if (setIsScreenWidth600) {
      const textElement = textRef.current;
      if (textElement) {
        const range = document.createRange();
        range.selectNode(textElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
      }
    }

    if (!setIsScreenWidth600) {
      navigator.clipboard.writeText(text);
    }

    setCodigo(true);
    setTimeout(() => {
      setCodigo(false);
    }, 1000);
  };

  const handleInscription = () => {
    const body = {
      member_id: login.id,
      contest_id: idConcurso,
    };

    dispatch(postParticipanteConcursoAsync({ body, token: login.token }));
    setOpenModalConcurso(false);
    setOpenModal(true);
  };

  function truncateString(str) {
    if (str.length <= 20) {
      return str;
    }
    return str.slice(0, 20) + "...";
  }

  return (
    <>
      {tipo === "concursos" && (
        <div className="container-tarjeta-oyente">
          <div className="box-img-mobile-exact">
            <img src={img} alt="Concursos" className="img-tarjeta-oyente" />
          </div>

          <div className="box-tarjeta-oyente">
            <h2 className="titulo-tarjeta-oyente">
              {isScreenWidth600 ? truncateString(titulo) : titulo}
            </h2>

            <div className="box-texto-tarjeta-oyente">
              <div>
                <p className="texto-tarjeta-oyente">
                  Programa:{" "}
                  {isScreenWidth600
                    ? truncateString(programaSorteo)
                    : programaSorteo}
                </p>
                <p className="texto-tarjeta-oyente">
                  Finalización: {finalizacionConcurso}
                </p>
              </div>
              {estadoSorteo === "FINALIZADO" || estadoSorteo === "ENTREGADO" ? (
                !isScreenWidth600 && (
                  <div className="box-ganador-boton-pc">
                    <p>
                      El ganador es:{" "}
                      <span className="bold-nombre-ganador">
                        {ganador?.fullName}
                      </span>
                    </p>

                    <Boton
                      text={"Sorteo finalizado"}
                      type={3}
                      hidden={isScreenWidth600}
                    />
                  </div>
                )
              ) : (!findConcurso) ? (
                <Boton
                  text={"Inscribirse"}
                  type={2}
                  onClick={handleInscription}
                  hidden={isScreenWidth600}
                />
              ) : (
                <Boton
                  text={"Inscripto"}
                  type={3}
                  onClick={handleInscription}
                  hidden={isScreenWidth600}
                />
              )}
            </div>

            <div className="mobile-botones-tarjetas">
              {estadoSorteo === "PENDIENTE" && (!findConcurso) ? (
                <p
                  className="ver-mas-tarjeta"
                  onClick={() => setOpenModalConcurso(true)}
                >
                  + Ver más
                </p>
              ) : (
                <p
                  className="ver-mas-tarjeta ver-mas-tarjeta-oculta"
                  onClick={() => setOpenModalConcurso(true)}
                >
                  + Ver más
                </p>
              )}

              {(estadoSorteo === "FINALIZADO" ||
                estadoSorteo === "ENTREGADO") &&
                isScreenWidth600 ? (
                <div className="box-ganador-boton">
                  <p>
                    El ganador es:{" "}
                    <span className="bold-nombre-ganador">
                      {ganadores.join(",")}
                    </span>
                  </p>
                  <Boton
                    text={"Sorteo finalizado"}
                    type={5}
                    hidden={!isScreenWidth600 && true}
                  />
                </div>
              ) : findConcurso === undefined ? (
                <Boton
                  text={"Inscribirse"}
                  type={4}
                  onClick={handleInscription}
                  hidden={!isScreenWidth600 && true}
                />
              ) : (
                <Boton
                  text={"Inscripto"}
                  type={5}
                  onClick={handleInscription}
                  hidden={!isScreenWidth600 && true}
                />
              )}
            </div>
          </div>
          {openModal && (
            <Modal
              setOpenModal={setOpenModal}
              info={infoModal}
              titulo={titulo}
              iconOk={true}
              hiddenClose
            />
          )}
          {openModalConcurso && (
            <Modal
              setOpenModalConcurso={setOpenModalConcurso}
              onClick={handleInscription}
              titulo={titulo}
              concurso={true}
              fechConcurso={finalizacionConcurso}
              nombrePrograma={nombrePrograma}
              parrafo={parrafo}
              imgConcurso={imgModalConcurso}
            />
          )}
        </div>
      )}

      {tipo === "mis concursos" && (
        <div className="container-tarjeta-oyente">
          <div className="box-tarjeta-oyente">
            <h2 className="titulo-tarjeta-oyente">{titulo}</h2>

            <div className="box-texto-tarjeta-oyente">
              <div>
                <p className="texto-tarjeta-oyente">
                  {estadoMiSorteo === "PENDIENTE"
                    ? `Estado del sorteo: ${estadoMiSorteo}`
                    : `Ganadores: ${[ganadores.join(",")]}`}
                </p>
                <p className="texto-tarjeta-oyente">
                  Participaste el: {fechaParticipacion}
                </p>
              </div>

              {resultadoMiSorteo === "pendiente" && (
                <>
                  <div>
                    <h2 className="info-tarjeta-oyente">
                      ¡Aún no se realizó el sorteo!
                    </h2>
                  </div>
                </>
              )}

              {resultadoMiSorteo === "ganado" && (
                <>
                  <div>
                    <h2 className="info-tarjeta-oyente">
                      ¡Felicitaciones, ganaste!
                    </h2>
                    <p
                      className="retiro-premio-tarjeta"
                      onClick={() => setOpenModal(true)}
                    >
                      ¿Cómo retiro mi premio?
                    </p>
                  </div>
                </>
              )}

              {resultadoMiSorteo === "perdido" && (
                <>
                  <div>
                    <h2 className="info-tarjeta-oyente">¡Sigue intentando!</h2>
                  </div>
                </>
              )}
            </div>
          </div>
          {openModal && (
            <Modal
              setOpenModal={setOpenModal}
              info={infoModal}
              titulo={titulo}
              ganado={true}
            />
          )}
        </div>
      )}

      {tipo === "beneficios" && (
        <div className="container-tarjeta-oyente">
          <img
            src={img}
            alt={titulo.slice(0, 20)}
            className="img-tarjeta-oyente"
          />

          <div className="box-tarjeta-oyente box-tarjeta-oyente-mobile">
            <h2 className="titulo-tarjeta-oyente">
              {isScreenWidth600 ? truncateString(titulo) : titulo}
            </h2>

            <div className="box-texto-tarjeta-oyente box-tarjeta-oyente-mobile">
              <div>
                <p className="texto-tarjeta-oyente">
                  Valido hasta el: {fechaFinBeneficio}
                </p>
                <p className="texto-tarjeta-oyente">
                  Para ser usado en{" "}
                  {isScreenWidth600
                    ? truncateString(usoBeneficio)
                    : usoBeneficio}
                </p>
                <p
                  className="ver-mas-tarjeta"
                  onClick={() => setOpenModalBeneficio(true)}
                >
                  + Ver más
                </p>
              </div>

              <div className="codigo-descuento-mobile">
                <h2 className="info-tarjeta-oyente info-tarjeta-oyente-mobile">
                  Código de descuento
                </h2>
                {codigo ? (
                  <p className="info-descuento-tarjeta-oyente">
                    ¡Código copiado!
                  </p>
                ) : (
                  <p
                    className="info-descuento-tarjeta-oyente"
                    ref={textRef}
                    onClick={() => handleCopy(codigoDescuento)}
                  >
                    {codigoDescuento}{" "}
                    <ContentCopyIcon sx={{ fontSize: "12px" }} />
                  </p>
                )}
                {openModalBeneficio && (
                  <Modal
                    setOpenModalConcurso={setOpenModalBeneficio}
                    titulo={titulo}
                    type={tipo}
                    codigo={codigo}
                    fechConcurso={fechaFinBeneficio}
                    nombrePrograma={codigoDescuento}
                    parrafo={usoBeneficio}
                    imgConcurso={img}
                    handleCopy={handleCopy}
                    codeRef={textRef}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TarjetaOyente;
