import { createAsyncThunk } from "@reduxjs/toolkit"
import { postRegister } from "./registroApi";

export const postRegisterAsync = createAsyncThunk(
    'register/postRegister',
    async (args) => {
        try {
            const response = await postRegister(args);
            return response
        } catch (err) {
            throw new Error(JSON.stringify(err.response));
        }
    }
);