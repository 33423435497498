import { createAsyncThunk } from "@reduxjs/toolkit"
import { postLogin } from "./loginApi";

export const postLoginAsync = createAsyncThunk(
    'login/postLogin',
    async (args) => {
        const {email, password, google, google_id,google_email, google_name,} = args
        const response = await postLogin(email, password, google_id,google_email,google_name);
        return {response, google}
    }
);