import logo from "../../assets/logo-lu5.svg";
import { Button } from "@mui/material";
import "./redirecciones.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
function Redirecciones() {
  return (
    <div style={{ width: "70%", justifyContent: "space-evenly" }}>
      <div className="header-sesion" style={{ flexDirection: "row" }}>
        <Button
          variant="contained"
          onClick={() => window.open("https://lu5am.com/en-vivo/", "_blank")}
        >
          Escuchá la radio en vivo <PlayArrowIcon />
        </Button>
        <img src={logo} alt="Logo LU5" className="logo-sesion" />
        <Button
          variant="contained"
          onClick={() =>
            window.open("https://lu5am.com/category/noticias/", "_blank")
          }
        >
          Leer las últimas noticias
        </Button>
      </div>
    </div>
  );
}

export default Redirecciones;
