/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { setStatusMessageNote, setRefreshNuevaNota } from "../../app/silices/notas/notaSlice"
import { getAllNotasAsync, postNotaAsync } from '../../app/silices/notas/notaThunk';
import Input from '../Input/Input';
import './agregarNota.css'

const charLimit_title = 50;
const charLimit_description = 80;

const AgregarNota = (props) => {
    const { setAgregar, statusMessageNote, login, setGetNote } = props;
    const [nuevaNota, setNuevaNota] = useState({
        title: "",
        image: "",
        description: "",
        link: "",
    });

    const { title, image, description, link } = nuevaNota;
    const dispatch = useDispatch();
    const [agregadoOk, setAgregadoOk] = useState(false);
    const [complete, setComplete] = useState(false);
    const [load, setLoad] = useState(false);
    const [saved, setSaved] = useState(false);
    const [titleChar, setTitleChar] = useState(0);
    const [descriptionChar, setDescriptionChar] = useState(0);

    useEffect(() => {
        if (statusMessageNote === 'fulfilledCreateNotas') {
            setAgregadoOk(true)
            setLoad(false)
            setTimeout(() => {
                dispatch(setRefreshNuevaNota())
            }, 2000)
        }

        if (statusMessageNote === 'rejectedCreateNotas') {
            setAgregadoOk(false)
            setLoad(false)
        }
    }, [statusMessageNote])

    useEffect(() => {
        if (!complete && agregadoOk) {
            setTimeout(() => {
                setComplete(true)
            }, 7000)
        }
    }, [complete])

    useEffect(() => {
        if (saved && complete) {
            setTimeout(() => {
                setSaved(false)
                setAgregar(false)
            }, 3000)
        }
    }, [saved])

    const handleChars = (name, value) => {
        if (name === "description") {
            setDescriptionChar(value.length);
        }
        else if (name === "title") {
            setTitleChar(value.length)
        }
        else return;
    }

    const handlerChangeImage = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const base64String = reader.result;
            const data64Imagen = base64String.split(',')
            setNuevaNota((nota) => ({ ...nota, image: data64Imagen[1] }))
        };

        reader.readAsDataURL(file);
    }


    const handleInputChange = ({ target: { name, value } }) => {
        handleChars(name, value);
        return setNuevaNota((nota) => ({
            ...nota,
            [name]: value
        }))
    }


    const discardImage = () =>
        setNuevaNota((nota) => ({ ...nota, image: '' }))


    const discardNote = () => {
        dispatch(setRefreshNuevaNota())
        setAgregar(false)
    }

    const saveData = async () => {
        setComplete(false);

        if ((title && image && description && link)) {
            await setGetNote(true);
            await setComplete(true);
            await setLoad(true);
            await dispatch(postNotaAsync({ token: login.token, body: nuevaNota }));
            await dispatch(setStatusMessageNote('fulfilledCreateNotas'))

        }
        await dispatch(getAllNotasAsync({ token: login.token }));
        setSaved(true);
    };

    return (
        <>
            <section className='container-superior-beneficio-agregar'>
                <div className='container-beneficio-agregar'>
                    <div className='boxsuperior-beneficio-agregar'>
                        <div className='box-beneficio-agregar'>
                            <div className='img-input-beneficio-agregar'>
                                {
                                    nuevaNota.image === '' ?
                                        <>
                                            <span>Agregar imagen</span>
                                            <div>
                                                <EditIcon sx={{ fontSize: '15px' }} />
                                            </div>
                                            <input type='file' onChange={handlerChangeImage} />
                                        </>
                                        :
                                        <>
                                            <img src={`data:image/jpg;base64,${nuevaNota.image}`} alt='Nueva Nota' className='vista-img-agregar-benedicio' />
                                            <div className='box-icon-imagen-agregar-concurso'>
                                                <DeleteForeverIcon onClick={discardImage} />
                                            </div>
                                        </>

                                }
                            </div>
                        </div>
                    </div>

                    <div className='inbox-beneficio-agregar'>
                        <div>
                            <div className='input_with__char_limit'>
                                <span className='span-beneficio-agregar'>Título:</span>
                                <span className={`span-beneficio-agregar ${titleChar >= charLimit_title ? "limit__Char" : ""}`}>{titleChar}/{charLimit_title}</span>

                            </div>
                            <Input width={10} type='text' placeholder='Título' name="title" onChange={handleInputChange} color maxLength={charLimit_title} />
                        </div>


                        <div>
                            <div className='input_with__char_limit'>
                                <span className='span-beneficio-agregar'>Descripción:</span>
                                <span className={`span-beneficio-agregar ${descriptionChar >= charLimit_description ? "limit__Char" : ""}`}>{descriptionChar}/{charLimit_description}</span>
                            </div>
                            <Input width={10} type='text' placeholder='Descripción' name="description" onChange={handleInputChange} color maxLength={charLimit_description} />
                        </div>
                        <div>
                            <span className='span-beneficio-agregar'>Link de promoción:</span>
                            <Input width={10} type='text' placeholder='Link' name="link" onChange={handleInputChange} color />
                        </div>

                    </div>

                </div>

                <div className='boxsuperior-botones-beneficio-agregar'>
                    {
                        (load) ? <span className='span-ok-registro span-cargando '>... Cargando</span>
                            :
                            (saved && agregadoOk && complete)
                                ?
                                <span className='span-ok-registro'>¡Se agregó la nota correctamente!</span>
                                :
                                (!complete && saved)
                                    ?
                                    <span className='span-error-registro'>¡Datos incompletos complete todos los campos!</span>
                                    : (!agregadoOk && saved) ?
                                        <span className='span-error-registro'>¡No se pudo agregar la nota, los datos estan incompletos o incorrectos!</span>
                                        : null
                    }

                    <button className='boton-guardar-beneficio-agregar' onClick={saveData}>
                        <SaveIcon />
                        Guardar
                    </button>

                    <button className='boton-eliminar-beneficio-agregar' onClick={discardNote}>
                        <DeleteForeverIcon />
                        Descartar
                    </button>
                </div>
            </section>
        </>
    );
};

export default AgregarNota;